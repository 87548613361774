import * as React from 'react';
import {styled, useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Stack from '@mui/material/Stack';
import Logo from "images/TPP_Logo_Rev.svg";
import LogoIcon from "images/TPP_Icon_Rev.svg";
import UserIcon from "images/User_Icon.svg";
import MainMenuList from "components/molecules/Menu/MainMenuList";
import MainMenuDivider from "components/atoms/Menu/MainMenuDivider";
import {toggleMenuOpen} from "redux/global/globalSlice";
import {routes} from "routes";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.primary.main,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    color: 'white',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),

    '& .MuiListItemIcon-root, & .MuiListItemText-root' : {
      color: theme.text.light
    },

  }),
);

const MenuToggleButton = styled(IconButton)(
  ({theme}) => ({
    position: "absolute",
    backgroundColor: theme.main.bgDark,
    top: "67px",
    right: "-0px",
    zIndex: "1000",
    '&:hover' : {
      backgroundColor: theme.main.bgDark
    }
  }),
)


const MenuUserBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({theme, open}) => ({
    color: theme.palette.light.main,
    height: "80px",
    margin: "10px 10px 0",
    "& .user-image" : {
      "& > img" : {
        transition: "all 0.2s ease-out",
        width: open? "80px" : "45px",
        marginTop: open? "0" : "25px",
        marginBottom: open? "0" : "10px"
      }
    },
    "& .user-info" : {
      display: open? "block" : "none",
      paddingTop: "15px",
      "& h5" : {
        marginTop: "-5px",
        color: theme.text.light,
        fontWeight: "bold"
      }
    }
  }),
)


export default function MainMenu() {
  const menuOpen = useSelector((state) => state.global.menuOpen);
  const dispatch = useDispatch();
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const profile = useSelector(state => state.userProfile.data);

  const handleDrawerToggle = () => {
    dispatch(toggleMenuOpen())
  };

  return (

      <Drawer variant="permanent" open={menuOpen}  sx={{ pb: 20 }}>

        <Box sx={{pt: 3, pb: 2}}>
            <RouterLink to={routes.leaderBoard}>
          {menuOpen ?
              <a href="/"><img src={Logo} alt="Portfolio Platform" width={130} style={{marginLeft: "70px", marginRight: "70px"}}/></a> :
              <a href="/"><img src={LogoIcon} alt="Portfolio Platform" width={65} height={44} style={{marginBottom: "-5px"}}/></a>
          }
            </RouterLink>
        </Box>
        <MainMenuDivider />

        <MenuToggleButton color="secondary" onClick={handleDrawerToggle} >
          {menuOpen ? <KeyboardArrowLeftIcon color="success" /> : <KeyboardArrowRightIcon color="success" />}
        </MenuToggleButton>

        {isAuthorised &&
        <MenuUserBox  open={menuOpen}>
          <Stack direction="row" spacing={2}>
            <Box className="user-image">
              <img src={UserIcon} alt="" />
            </Box>
            <Box className="user-info">
              <Box component="span">Welcome Back</Box>
              <Typography variant="h5">{profile.first_name}</Typography>
            </Box>
          </Stack>
        </MenuUserBox>
        }

      <MainMenuList open={menuOpen}/>

      </Drawer>

  );
}
