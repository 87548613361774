import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {Paper} from "components/atoms/Paper/Paper";
import {Link} from "@mui/material";

export const ResultsDisclaimer = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
      <Paper variant="light2">
        <Typography variant="h5">About the results you see on this Web site</Typography>
        <Typography component="p" sx={{mt:2}}>Past results are not necessarily indicative of future results.</Typography>
        <Typography component="p" sx={{mt:2}}>These results are based on simulated or hypothetical performance results. Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.</Typography>
        <Typography component="p" sx={{mt:2}}>One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results.</Typography>
        <Typography component="p" sx={{mt:2}}>You may be interested to <Link>learn more technical details</Link> about how The Portfolio Platform calculates the hypothetical results you see on this web site.</Typography>
        <Typography variant="h5" sx={{mt:2}}>Material assumptions and methods used when calculating results</Typography>
        <Typography component="p" sx={{mt:2}}>The following are material assumptions used when calculating any hypothetical monthly results that appear on our web site. </Typography>
        <ul>
          <li><strong>Profits are reinvested.</strong> We assume profits (when there are profits) are reinvested in the trading strategy.</li>
          <li><strong>Starting investment size.</strong> For any trading strategy on our site, hypothetical results are based on the assumption that you invested the starting amount shown on the strategy's performance chart. In some cases, nominal dollar amounts on the equity chart have been re-scaled downward to make current go-forward trading sizes more manageable. In these cases, it may not have been possible to trade the strategy historically at the equity levels shown on the chart, and a higher minimum capital was required in the past.</li>
          <li><strong>All fees are included.</strong> When calculating cumulative returns, we try to estimate and include all the fees a typical trader incurs when AutoTrading using AutoTrade technology. This includes the subscription cost of the strategy, plus any per-trade AutoTrade fees, plus estimated broker commissions if any.</li>
          <li><strong>"Max Drawdown" Calculation Method.</strong> We calculate the Max Drawdown statistic as follows. Our computer software looks at the equity chart of the system in question and finds the largest percentage amount that the equity chart ever declines from a local "peak" to a subsequent point in time (thus this is formally called "Maximum Peak to Valley Drawdown.") While this is useful information when evaluating trading systems, you should keep in mind that past performance does not guarantee future results. Therefore, future drawdowns may be larger than the historical maximum drawdowns you see here.</li>
        </ul>
        <Typography variant="h5" sx={{mt:2}}>Trading is risky </Typography>
        <Typography component="p" sx={{mt:2}}>There is a substantial risk of loss in futures and forex trading. Online trading of stocks and options is extremely risky. Assume you will lose money. Don't trade with money you cannot afford to lose.</Typography>
      </Paper>
  )
}