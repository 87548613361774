import React, {useEffect, useState} from 'react';
import _ from "lodash"
import {useDispatch, useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import {selectStrategyById} from 'redux/strategy/strategySlice';
import {Field, Form, Formik} from 'formik';
import {Button, Card, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, Switch, Table} from '@mui/material';
import {Checkbox, RadioGroup, Select as SelectInput, TextField} from 'formik-mui';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Divider from "@mui/material/Divider";
import {selectSubscriptionByStrategyId, updateSubscription} from "redux/subscription/subscriptionSlice";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import InputAdornment from "@mui/material/InputAdornment";
import {useConfirmation} from "utils/ConfirmationProvider";


const AutoTradeForm = () => {
  const dispatch = useDispatch();
  const confirm = useConfirmation()
  const [formSectionsActive, setFormSectionsActive] = useState({
    tradeManagement: false,
    stopLoss: false,
    autoTradeScaling: false,
  });
  const params = useParams();
  const navigate = useNavigate();
  const strategyId = params.strategyId;
  const strategy = useSelector((state) => selectStrategyById(state, strategyId)) ;
  const subscription = useSelector((state) => selectSubscriptionByStrategyId(state, strategyId))

  useEffect(() => {

    let tradeManagement = false;
    let stopLoss = false;
    let autoTradeScaling = false;


    if(subscription[0].autotrading_data){
      if (!_.isEqual(subscription[0].autotrading_data.tradeManagement, defaultInitialValues.tradeManagement)){
        tradeManagement = true;
      }
      if (!_.isEqual(subscription[0].autotrading_data.stopLoss, defaultInitialValues.stopLoss)){
        stopLoss = true;
      }
      if (!_.isEqual(subscription[0].autotrading_data.autoTradeScaling, defaultInitialValues.autoTradeScaling)){
        autoTradeScaling = true;
      }
    }

    setFormSectionsActive({
      tradeManagement: tradeManagement,
      stopLoss: stopLoss,
      autoTradeScaling: autoTradeScaling
    })
  }, []);

  const handleFormSectionToggle = (section, setFieldValue) => {
    setFormSectionsActive({
      ...formSectionsActive,
      [section]: !formSectionsActive[section]
    })
    setFieldValue(section, defaultInitialValues[section])
  }

  const handleStartAutoTrading = async (values) => {
    await dispatch(updateSubscription({subscriptionId: subscription[0].id, subscriptionData: {"autotrading_status": "live" }})).unwrap();
    enqueueSnackbar('AutoTrade has been enabled.', {variant: 'success'})
  }

  const handleStopAutoTrading = async (values) => {
    confirm({
      title: `Stop AutoTrading`,
      message: <>Are you sure you want to turn off AutoTrading for the strategy <b>{strategy.name}</b>?</>,
      onConfirm: async () => {
        await dispatch(updateSubscription({subscriptionId: subscription[0].id, subscriptionData: {"autotrading_status": "inactive" }})).unwrap();
        enqueueSnackbar('AutoTrade has been disabled.', {variant: 'success'})
      },
      maxWidth: "md"
    });
  }

  const handleSaveChanges = async (values, resetForm) => {

    if(!formSectionsActive["tradeManagement"]){
      values.tradeManagement = defaultInitialValues["tradeManagement"];
    }
    if(!formSectionsActive["stopLoss"]){
      values.stopLoss = defaultInitialValues["stopLoss"];
    }
    if(!formSectionsActive["autoTradeScaling"]){
      values.autoTradeScaling = defaultInitialValues["autoTradeScaling"];
    }

    await dispatch(updateSubscription({subscriptionId: subscription[0].id, subscriptionData: {"autotrading_data": values }})).unwrap();
    enqueueSnackbar('AutoTrade configuration has been saved.', {variant: 'success'})

    resetForm({values})

  }


const defaultInitialValues = {
  tradeManagement: {
    stocks: { autotrade: false, trade_size: '' },
    forex: { autotrade: false, trade_size: '' },
    features: { autotrade: false, trade_size: '' },
    options: { autotrade: false, trade_size: '' },
    short_options: 'yes'
  },
  stopLoss: "0",
  autoTradeScaling: "1"
};

const initialValues = subscription[0].autotrading_data ? subscription[0].autotrading_data : defaultInitialValues;


  return (
    <>

      <PageHeader text1={"Review AutoTrade "} text2={"settings"} description={strategy.name}/>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <Card sx={{mt: 3, p: 3}}>
            <Formik initialValues={initialValues} onSubmit={handleStartAutoTrading}>
              {({isSubmitting, values, dirty, resetForm, setFieldValue}) => (
                <Form>
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="mediumText">Trade Management</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Default</Typography>
                      <Field component={Switch} type="checkbox" name="switch" color="secondary" checked={formSectionsActive["tradeManagement"]} onClick={() => {handleFormSectionToggle("tradeManagement", setFieldValue) }} />
                      <Typography>Modify</Typography>
                    </Stack>
                  </Stack>
                  <Collapse in={formSectionsActive["tradeManagement"]} timeout="auto" unmountOnExit >
                     <Box sx={{ml: 2, mr: 2}}>
                    <TableContainer sx={{mt: 5, mb: 7}} >
                      <Table size="small" >
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="center">Strategy Often Trades</TableCell>
                            <TableCell align="center">You want to AutoTrade</TableCell>
                            <TableCell align="center">Max Trade Size</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">Stocks</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">
                              <Field component={Checkbox} type="checkbox" name="tradeManagement.stocks.autotrade" color="secondary" />
                            </TableCell>
                            <TableCell align="center">
                              <FormControl fullWidth sx={{ m: 1 }}>
                                <Field
                                  component={TextField}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                  }}
                                  size="small"
                                  name="tradeManagement.stocks.trade_size"
                                  color="secondary"
                                  type="number"
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Forex</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">
                              <Field component={Checkbox} type="checkbox" name="tradeManagement.forex.autotrade" color="secondary" />
                            </TableCell>
                            <TableCell align="center">
                              <FormControl fullWidth sx={{ m: 1 }}>
                                <Field
                                  component={TextField}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">minilots</InputAdornment>,
                                  }}
                                  size="small"
                                  name="tradeManagement.forex.trade_size"
                                  color="secondary"
                                  type="number"
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">Features</TableCell>
                            <TableCell align="center">
                              <Field component={Checkbox} type="checkbox" checked color="secondary" />
                            </TableCell>
                            <TableCell align="center">
                              <Field component={Checkbox} type="checkbox" name="tradeManagement.features.autotrade" color="secondary" />
                            </TableCell>
                            <TableCell align="center">
                              <FormControl fullWidth sx={{ m: 1 }}>
                                <Field
                                  component={TextField}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">contracts</InputAdornment>,
                                  }}
                                  size="small"
                                  name="tradeManagement.features.trade_size"
                                  color="secondary"
                                  type="number"
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" >Options</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">
                              <Field component={Checkbox} type="checkbox" name="tradeManagement.options.autotrade" color="secondary" />
                            </TableCell>
                            <TableCell align="center">
                              <FormControl fullWidth sx={{ m: 1 }}>
                                <Field
                                  component={TextField}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">contracts</InputAdornment>,
                                  }}
                                  size="small"
                                  name="tradeManagement.options.trade_size"
                                  color="secondary"
                                  type="number"
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>


                        <FormControl sx={{mb: 5}}>
                          <FormLabel>Allow short options in broker account?</FormLabel>
                          <Field component={RadioGroup} row name="tradeManagement.short_options">
                            <FormControlLabel value="yes" control={<Radio color="secondary"/>} label="Allow short options" />
                            <FormControlLabel value="no" control={<Radio color="secondary"/>} label="No short options" />
                          </Field>
                        </FormControl>

                     </Box>

                  </Collapse>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="mediumText">Stop Loss</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Default</Typography>
                      <Switch color="secondary" checked={formSectionsActive["stopLoss"]} onClick={() => {handleFormSectionToggle("stopLoss", setFieldValue)}}/>
                      <Typography>Modify</Typography>
                    </Stack>
                  </Stack>
                  <Collapse in={formSectionsActive["stopLoss"]} timeout="auto" unmountOnExit>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth margin="normal" size="normal" >
                            <Field component={SelectInput} id="stopLoss" name="stopLoss" label="Stop Loss">
                              <MenuItem value="0">Strategy Default</MenuItem>
                              <MenuItem value="250">$250</MenuItem>
                              <MenuItem value="500">$500</MenuItem>
                              <MenuItem value="750">$750</MenuItem>
                              <MenuItem value="100">$100</MenuItem>
                              <MenuItem value="1500">$1500</MenuItem>
                              <MenuItem value="2000">$2000</MenuItem>
                              <MenuItem value="2500">$2500</MenuItem>
                              <MenuItem value="3500">$3500</MenuItem>
                              <MenuItem value="4000">$4000</MenuItem>
                              <MenuItem value="4500">$4500</MenuItem>
                              <MenuItem value="5000">$5000</MenuItem>
                              <MenuItem value="5500">$5500</MenuItem>
                              <MenuItem value="6000">$6000</MenuItem>
                              <MenuItem value="6500">$6500</MenuItem>
                              <MenuItem value="7000">$7000</MenuItem>
                              <MenuItem value="7500">$7500</MenuItem>
                              <MenuItem value="8000">$8000</MenuItem>
                              <MenuItem value="8500">$8500</MenuItem>
                              <MenuItem value="9000">$9000</MenuItem>
                              <MenuItem value="10000">$10000</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                  </Collapse>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography variant="mediumText">AutoTrade Scaling</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Default</Typography>
                        <Switch color="secondary" checked={formSectionsActive["autoTradeScaling"]} onClick={() => {handleFormSectionToggle("autoTradeScaling", setFieldValue)}}/>
                      <Typography>Modify</Typography>
                    </Stack>
                  </Stack>
                  <Collapse in={formSectionsActive["autoTradeScaling"]} timeout="auto" unmountOnExit>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth margin="normal" size="normal" >
                            <Field component={SelectInput} id="autoTradeScaling" name="autoTradeScaling" label="AutoTrade Scaling">
                              <MenuItem value="0.1">10%</MenuItem>
                              <MenuItem value="1">100%</MenuItem>
                              <MenuItem value="2">200%</MenuItem>
                              <MenuItem value="3">300%</MenuItem>
                              <MenuItem value="4">400%</MenuItem>
                              <MenuItem value="5">500%</MenuItem>
                              <MenuItem value="6">600%</MenuItem>
                              <MenuItem value="7">700%</MenuItem>
                              <MenuItem value="8">800%</MenuItem>
                              <MenuItem value="9">900%</MenuItem>
                            </Field>
                          </FormControl>
                        </Grid>
                      </Grid>
                  </Collapse>

                  <Divider sx={{mt: 3, mb:3}}/>

                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    {subscription[0].autotrading_status === 'live' ? <Button sx={{mt: 2}} variant="contained" color="error" onClick={() => handleStopAutoTrading(values, resetForm)}> Stop AutoTrade </Button> : <span></span>}
                  <Typography align="right">
                    {dirty && <Button sx={{mt: 2}} variant="contained" color="secondary" onClick={() => handleSaveChanges(values, resetForm)}> Save Changes </Button>}
                    &nbsp;
                    {subscription[0].autotrading_status === 'inactive' && <Button sx={{mt: 2}} type="submit" variant="contained" color="secondary" disabled={isSubmitting || dirty}> Turn On AutoTrade </Button>}
                  </Typography>

                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{mt: 3}}>
            <Typography variant="h5"></Typography>
            <Typography component="p" sx={{mt: 2}}>
            </Typography>
          </Box>
        </Grid>

      </Grid>
    </>
  )

};

export default AutoTradeForm;
