import {styled} from "@mui/material/styles";
import Divider from "@mui/material/Divider";

const MainMenuDivider = styled(Divider)(
  ({theme}) => ({
    borderColor: theme.palette.light.main,
    margin: '0 20px 0 20px',
    opacity: '0.3'
  }),
)

export default MainMenuDivider;