import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";
import {deleteStrategy} from "redux/strategy/strategySlice";

// Thunks
export const fetchSubscriptions = createAsyncThunk('subscription/fetchSubscriptions', async () => {
  return authAxios.get('subscription/subscriptions/').then(response => response.data);
});

export const createSubscription = createAsyncThunk('subscription/createSubscription', (subscriptionData) => {
  return authAxios.post('subscription/subscriptions/', subscriptionData).then(response => response.data);
});

export const updateSubscription = createAsyncThunk('subscription/updateSubscription', ({ subscriptionId, subscriptionData }) => {
  console.log(subscriptionId, subscriptionData);
  return authAxios.put(`subscription/subscriptions/${subscriptionId}/`, subscriptionData).then(response => response.data);
});

export const deleteSubscription = createAsyncThunk('subscription/deleteSubscription', (subscriptionId) => {
  return authAxios.delete(`subscription/subscriptions/${subscriptionId}/`).then(response => response.data);
});

export const fetchSubscriptionDetails = createAsyncThunk('subscription/fetchStripeDetails', async (subscriptionId) => {
  return authAxios.get(`subscription/subscriptions/${subscriptionId}/details/`).then(response => response.data);
});


export const subscriptionsAdapter = createEntityAdapter();
// Slice
export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: subscriptionsAdapter.getInitialState({
    loading: false,
    error: null,
    subscriptionDetails: {},
  }),
  reducers: {
  },
   extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        subscriptionsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, subscriptionsAdapter.addOne)
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubscription.fulfilled, subscriptionsAdapter.upsertOne)
      .addCase(updateSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        const subscriptionId = action.meta.arg;
        subscriptionsAdapter.removeOne(state, subscriptionId);
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action) => {
        const subscriptionId = action.meta.arg;
        state.subscriptionDetails[subscriptionId] = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectById: selectSubscriptionById,
  selectAll: selectAllSubscriptions,
} = subscriptionsAdapter.getSelectors((state) => state.subscription);

export const selectSubscriptionByStrategyId = (state, strategyId) => {
    return Object.values(state.subscription.entities).filter(subscription => subscription.strategy === strategyId);
};

export const selectAllSubscriptionStrategyIds = (state) => {
    return Object.values(state.subscription.entities).map(sub => sub.strategy);
};

export const selectAllSubscriptionsLive = (state) => {
  return Object.values(state.subscription.entities)
    .filter(subscription => subscription.autotrading_status === 'live');
};

export const selectAllSubscriptionsInactive = (state) => {
  return Object.values(state.subscription.entities)
    .filter(subscription => subscription.autotrading_status === 'inactive');
};

export default subscriptionSlice.reducer;
