import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthorised: false,
  contentLoading: true,
  menuOpen: true
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleMenuOpen: state => {
      state.menuOpen = !state.menuOpen
    },
    setMenuOpen: state => {
      state.menuOpen = true
    },
    setMenuClose: state => {
      state.menuOpen = false
    },
    setContentLoading: (state, action) => {
      state.contentLoading = action.payload
    },
    setAuthorised: (state, action) => {
      state.isAuthorised = action.payload
    },
  },
})

export default globalSlice.reducer
export const { toggleMenuOpen, setMenuOpen, setMenuClose, setAuthorised, setContentLoading } = globalSlice.actions