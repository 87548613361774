import React, {useState} from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {Box} from "@mui/system";
import Simulation from "views/Dashboard/Simulation";
import Inactive from "views/Dashboard/Inactive";
import Live from "views/Dashboard/Live";


export default function Dashboard() {

  const [dashboard, setDashboard] = useState('simulation');

  return (
    <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb:5 }}>
          <ButtonGroup variant="outlined">
            <Button variant={dashboard === 'simulation'? "contained" : "outlined"} onClick={() => setDashboard('simulation')}>Simulation</Button>
          </ButtonGroup>
          <Button disabled sx={{ color: 'black !important', pl: 5}}>SUBSCRIBED: </Button>
          <ButtonGroup variant="outlined">
            <Button variant={dashboard === 'subscribed'? "contained" : "outlined"} onClick={() => setDashboard('subscribed')}>Inactive</Button>
            <Button variant={dashboard === 'live'? "contained" : "outlined"} onClick={() => setDashboard('live')}>Live</Button>
          </ButtonGroup>
        </Box>

      {dashboard === 'simulation' && <Simulation />}
      {dashboard === 'subscribed' && <Inactive />}
      {dashboard === 'live' && <Live />}
    </>
  )
}