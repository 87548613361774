import React from "react";
import _ from "lodash";
import {useDispatch, useSelector} from 'react-redux';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BoardTable from "components/atoms/BoardTable/BoardTable";
import Typography from "@mui/material/Typography";
import {Alert, Chip, Grid, Link, MenuList, TableSortLabel} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import CheckIcon from '@mui/icons-material/Check';
import {Box} from "@mui/system";
import Stack from "@mui/material/Stack";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "routes";
import {selectAllSimulations} from "redux/simulation/simulationSlice";
import {selectAllSubscriptions, selectAllSubscriptionsInactive} from "redux/subscription/subscriptionSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import SettingsDropdown from "components/molecules/SettingsDropdown/SettingsDropdown";



export default function Inactive() {

  const subscriptions = useSelector(selectAllSubscriptionsInactive);
  const strategies = useSelector(state => state.strategy.entities);

  return (
    <>

      <PageHeader text1="Inactive" text2="Strategies not being traded" title="Dashboard - Inactive" description="These are strategies for which you have an active subscription, but which are not being configured for auto-trading."  />

      <Box sx={{mt: 5}}>
        {_.isEmpty(subscriptions) ?
          <Box sx={{mt: 4}}>
            <Alert severity="info">You have no Inactive strategies.</Alert>

          </Box> :
         <BoardTable fullWidth>
          <TableHead>

            <TableRow>
              <TableCell rowSpan={2}>Strategy</TableCell>
              <TableCell align="center">Inactive</TableCell>
              <TableCell align="center">Today P/L</TableCell>
              <TableCell align="center">Since Subscribe</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((subscription, index) => {
              const strategy = strategies[subscription.strategy];
              return (
                  <TableRow key={subscription.id}>
                    <TableCell className="light2" >
                      <Grid container>
                        <Grid item md={12} lg={6}>
                          <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                            <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                          </RouterLink>
                        </Grid>
                        <Grid item md={12} lg={6}>
                          <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>
                          <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                            <Button color="primary" variant="outlined" size="small" > Details </Button>
                          </RouterLink>
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center" className="light">
                      <Typography variant="largeText" color="secondary.dark" >{subscription.created.split('T')[0]}</Typography><br/>
                    </TableCell>
                    <TableCell  align="center" className="light">
                      <Typography variant="largeText" color="secondary.dark" >+$591</Typography><br/>
                    </TableCell>
                  <TableCell  align="center" className="light">
                    <Typography variant="largeText" color="secondary.dark" >($2.690)</Typography><br/>
                  </TableCell>
                    <TableCell align="right" sx={{width: 80}}>
                      <SettingsDropdown>
                        <MenuItem component={RouterLink} to={`${routes.autoTrade}`} >Change to Live &nbsp;(AutoTrade)</MenuItem>
                        <Divider/>
                        <MenuItem component={RouterLink} to={`${routes.account}?tab=subscriptions`}>Manage Subscription</MenuItem>
                      </SettingsDropdown>
              </TableCell>
                </TableRow>
              )
            })}

          </TableBody>
        </BoardTable>
        }
      </Box>

    </>
  )
}