import React from "react";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";


export default function WebTrader() {

  return (
    <>
      <PageHeader text1={"Web"} text2={"Trader"}/>
    </>
  )
}