import React from "react";
import _ from "lodash";
import {Box} from "@mui/system";
import Button from "@mui/material/Button";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import BoardTable from "components/atoms/BoardTable/BoardTable";
import {useSelector} from 'react-redux';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {Grid, TableSortLabel} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {Link as RouterLink} from "react-router-dom";
import SimulationSelection from "components/molecules/SimulationSelection/SimulationSelection";
import {selectAllSimulations} from "redux/simulation/simulationSlice";
import {routes} from "routes";
import MenuItem from "@mui/material/MenuItem";
import {ResultsDisclaimer} from "components/molecules/ResultsDisclaimer/ResultsDisclaimer";
import SettingsDropdown from "components/molecules/SettingsDropdown/SettingsDropdown";
import Tooltip from "@mui/material/Tooltip";


export default function Simulation() {
  const simulations = useSelector(selectAllSimulations);
  const strategies = useSelector(state => state.strategy.entities);

  return (
    <>
      <PageHeader text1={"Simulation"} text2={"Strategies"} title="Dashboard - Simulation Account" />

      {_.isEmpty(simulations) ?  <SimulationSelection /> :
      <>
      <BoardTable>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell colSpan={2} align="center" className="light" style={{ borderWidth: 0, borderTopWidth: '2px'}}>
              <Typography variant="mediumText" color="secondary.dark" >ALLOCATION</Typography>
            </TableCell>
            <TableCell align="center" className="light" style={{ borderWidth: 0, borderTopWidth: '2px'}} ><Typography variant="mediumText" color="secondary.dark" >TODAY</Typography></TableCell>
            <TableCell align="center" className="light" style={{ borderWidth: 0, borderTopWidth: '2px'}}><Typography variant="mediumText" color="secondary.dark" >ALL TIME</Typography></TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={2}>Strategy</TableCell>
            <TableCell rowSpan={2} align="center"><TableSortLabel active={true} direction="desc">Started</TableSortLabel></TableCell>
              <TableCell align="center"><TableSortLabel>Starting</TableSortLabel></TableCell>
              <TableCell align="center"><TableSortLabel>Latest</TableSortLabel></TableCell>
            <TableCell align="center"><TableSortLabel>Change %</TableSortLabel></TableCell>
            <TableCell align="center"><TableSortLabel>Change %</TableSortLabel></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {simulations.map((simulation, index) => {
            const strategy = strategies[simulation.strategy];
            return (
              strategy && <TableRow key={simulation.id}>
              <TableCell className="light2" >
                <Grid container>
                  <Grid item md={12} lg={6}>
                    <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                      <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                    </RouterLink>
                  </Grid>
                  <Grid item md={12} lg={6}>
                    <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>
                      <RouterLink>
                    <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                      <Button color="primary" variant="outlined" size="small" > Details </Button>
                    </RouterLink>
                      </RouterLink>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark">2021-10-05</Typography><br/>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark">$37,200</Typography><br/>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark">$37,100</Typography><br/>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark">+$31</Typography><br/>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark">($65)</Typography><br/>
              </TableCell>
              <TableCell align="right" sx={{width: 80}}>
               <SettingsDropdown>
                 <MenuItem component={RouterLink} to={`${routes.autoTrade}`} >Manage Simulation &nbsp;(AutoTrade)</MenuItem>
                 <MenuItem component={RouterLink} to={routes.strategyDetails.replace(":strategyId", strategy.id)}>Subscribe to Strategy</MenuItem>
                 <Tooltip title="You have to subscribe to enable AutoTrading" >
                   <span sx={{mb: 1}}> <MenuItem disabled>Change to Live &nbsp; (AutoTrade)</MenuItem> </span>
                 </Tooltip>
               </SettingsDropdown>
              </TableCell>
            </TableRow>
            )
          })}

        </TableBody>
      </BoardTable>


      <Box sx={{mt: 5}}>
        <ResultsDisclaimer/>
      </Box>
      </>
      }
    </>
  )
}