import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";
import {deleteStrategy} from "redux/strategy/strategySlice";

// Thunks
export const fetchWatchList = createAsyncThunk('fetchWatchList', async () => {
  return authAxios.get('watchlist/').then(response => response.data);
});

export const createWatchList = createAsyncThunk('createSubscription', (watchListData) => {
  return authAxios.post('watchlist/', watchListData).then(response => response.data);
});


export const deleteWatchList = createAsyncThunk('deleteSubscription', (watchListId) => {
  return authAxios.delete(`watchlist/${watchListId}/`).then(response => response.data);
});



export const watchListAdapter = createEntityAdapter();
// Slice
export const watchListSlice = createSlice({
  name: 'subscription',
  initialState: watchListAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
  },
   extraReducers: (builder) => {
    builder
      .addCase(fetchWatchList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWatchList.fulfilled, (state, action) => {
        watchListAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchWatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createWatchList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWatchList.fulfilled, watchListAdapter.addOne)
      .addCase(createWatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteWatchList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteWatchList.fulfilled, (state, action) => {
        const subscriptionId = action.meta.arg;
        watchListAdapter.removeOne(state, subscriptionId);
      })
      .addCase(deleteWatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const {
  selectById: selectSubscriptionById,
  selectAll: selectAllWatchList,
} = watchListAdapter.getSelectors((state) => state.watchlist);


export const selectAllWatchListStrategyIds = (state) => {
    return Object.values(state.watchList.entities).map(sub => sub.strategy);
};

export const selectStrategyToWatchListMapping = (state) => {
    return Object.values(state.watchList.entities).reduce((acc, watchlistItem) => {
        acc[watchlistItem.strategy] = watchlistItem.id;
        return acc;
    }, {});
};

export default watchListSlice.reducer;
