import { enqueueSnackbar } from 'notistack'

function parseErrorResponse(errorResponse) {
    const parsedErrors = [];

    for (const field in errorResponse) {
        const messages = errorResponse[field];
        const concatenatedMessage = messages.join('. '); // Concatenate messages for the same field
        const formattedMessage = `${concatenatedMessage}`;
        parsedErrors.push(formattedMessage);
    }

    return parsedErrors;
}


const NotificationMiddleware = store => next => action => {
  if (action.error) {
    let errorMessage = action.error.message;

    if (errorMessage.includes("code 401")) {
      errorMessage = ["Unauthorized! Please log in again."];
    } else if (errorMessage.includes("code 403")) {
      errorMessage = ["Forbidden! You do not have permission to perform this action."];
    } else {
      try {
        errorMessage = parseErrorResponse(JSON.parse(errorMessage).data);
      } catch (e) {
        errorMessage = [errorMessage];
      }
    }

    for (const message of errorMessage) {
      enqueueSnackbar(message, {variant: 'error'})
    }
  }


  return next(action);
};


export default NotificationMiddleware