import React, {useEffect} from "react";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import {useDispatch, useSelector} from 'react-redux';
import Typography from "@mui/material/Typography";
import BoardTable from "components/atoms/BoardTable/BoardTable";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Chip} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "routes";
import LinkIcon from '@mui/icons-material/Link';
import {Box} from "@mui/system";
import {fetchLinkedAccounts, selectAllLinkedAccounts} from "redux/linkedAccount/linkedAccountSlice";


export default function BrokerAccountList () {

  const dispatch = useDispatch();
  const linkedAccounts = useSelector(selectAllLinkedAccounts);
  const isAuthorised = useSelector(state => state.global.isAuthorised);

  useEffect(() => {
    // if (linkedAccounts.length === 0 && isAuthorised) {
    //   dispatch(fetchLinkedAccounts());
    // }
  },  [dispatch, linkedAccounts]);

  return (
    <>
      <Box sx={{ mb: 5, display: { xs: "block", sm: "flex"}}} justifyContent="space-between" alignItems="center">
        <PageHeader text1={"Broker"} text2={"Accounts"} description={""}/>
        <RouterLink to={routes.brokerAccountConnect}>
          <Button variant="contained" color="secondary" endIcon={<LinkIcon />}> Connect Broker Account </Button>
        </RouterLink>
      </Box>

      <BoardTable>
        <TableHead>
          <TableRow>
            <TableCell>Broker</TableCell>
            <TableCell></TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {linkedAccounts.map((account, index) => (
           <TableRow key={index}>
            <TableCell className="light2" >
              <Typography variant="h4" sx={{p:1}} >{ account.broker_name }</Typography>
            </TableCell>
            <TableCell  sx={{ width: { md: 'auto', lg: '50%'}}}></TableCell>
            <TableCell> U10455755 </TableCell>
            <TableCell align="right">
              <Chip label={account.status_name.toUpperCase()} size="small" color={account.status === "active" ? "success" : "default"}/>
            </TableCell>
          </TableRow>
      ))}

        </TableBody>
      </BoardTable>
    </>
  )
}