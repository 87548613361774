import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import {MenuList} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function SettingsDropdown({ children, buttonText }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const wrappedChildren = React.Children.map(children, child => {
    // Clone the child element and add an onClick handler
    return React.cloneElement(child, {
      onClick: (e) => {
        // If the child has its own onClick, call it
        if (child.props.onClick) {
          child.props.onClick(e);
        }
        // Close the dropdown
        handleSettingsClose();
      }
    });
  });

  return (
    <>
      <Button onClick={handleSettingsClick} variant="outlined" size="small" endIcon={<KeyboardArrowDownIcon/>} > {buttonText || "settings"} </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
      >
        <MenuList dense sx={{ pt: 0, pb: 0 }}>
          {wrappedChildren}
        </MenuList>
      </Menu>
    </>
  );
}

export default SettingsDropdown;
