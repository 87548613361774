import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchTrades = createAsyncThunk('trade/fetchTrades', async ({ strategyId, subscriptionId }) => {
  const url = strategyId ? `/strategy/${strategyId}/trades` : `/subscription/${subscriptionId}/trades`;
  return authAxios.get(url).then(response => response.data);
});

export const createTrade = createAsyncThunk('trade/createTrade', (tradeData) => {
  return authAxios.post('/trades', tradeData).then(response => response.data);
});

export const updateTrade = createAsyncThunk('trade/updateTrade', ({ tradeId, tradeData }) => {
  return authAxios.put(`/trades/${tradeId}`, tradeData).then(response => response.data);
});

export const deleteTrade = createAsyncThunk('trade/deleteTrade', (tradeId) => {
  return authAxios.delete(`/trades/${tradeId}`).then(response => response.data);
});

export const tradesAdapter = createEntityAdapter();

// Slice
export const tradeSlice = createSlice({
  name: 'trade',
  initialState: tradesAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrades.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        tradesAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createTrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTrade.fulfilled, tradesAdapter.addOne)
      .addCase(createTrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateTrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTrade.fulfilled, tradesAdapter.upsertOne)
      .addCase(updateTrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTrade.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTrade.fulfilled, tradesAdapter.removeOne)
      .addCase(deleteTrade.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectById: selectTradeById,
  selectAll: selectAllTrades,
} = tradesAdapter.getSelectors((state) => state.trade);

export default tradeSlice.reducer;