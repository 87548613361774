import React from 'react'
import _ from "lodash";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { fetchLinkedAccounts } from 'redux/linkedAccount/linkedAccountSlice';
import { fetchStrategies } from 'redux/strategy/strategySlice';
import {setContentLoading} from "redux/global/globalSlice";
import {fetchSubscriptions} from "redux/subscription/subscriptionSlice";
import {fetchWatchList} from "redux/watchlist/watchListSlice";
import {fetchSimulations} from "redux/simulation/simulationSlice";
import {routes} from "routes";
import {Box} from "@mui/system";
import Logo from "images/TPP_Logo_Rev.svg";
import CircularProgress from "@mui/material/CircularProgress";
import {fetchKYC} from "redux/kyc/kycSlice";


const AppProvider = ({children}) => {
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const userProfile = useSelector(state => state.userProfile.data);
  const isContentLoading = useSelector((state) => state.global.contentLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const promises = [];

      if(isAuthorised){
        if(_.isEmpty(userProfile) || userProfile === null){
            if(!window.location.href.includes(routes.userInitialProfile)) window.location.href = routes.userInitialProfile;
        }else {
          if(window.location.href.includes(routes.userInitialProfile)) window.location.href = routes.home;
        }

        if(window.location.href.includes(routes.KYCAnonymous)) window.location.href = routes.home;
      }

      if(isAuthorised){
        promises.push(dispatch(fetchLinkedAccounts()));
        promises.push(dispatch(fetchSimulations()));
        promises.push(dispatch(fetchSubscriptions()));
        promises.push(dispatch(fetchStrategies()));
        promises.push(dispatch(fetchWatchList()));
        promises.push(dispatch(fetchKYC()));
      } else {
        promises.push(dispatch(fetchStrategies()));
      }

      await Promise.all(promises);
      dispatch(setContentLoading(false));

    })();
  }, [isAuthorised, userProfile, dispatch]);

  return ( <> { isContentLoading?
    <>
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#181720', }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <img src={Logo} alt="Portfolio Platform" width={160} style={{ marginLeft: "8px", marginRight: "8px", marginBottom: "20px" }} />
                <CircularProgress color="secondary" />
            </Box>
        </Box>
      </>:

    children} </>);
}

export default AppProvider;
