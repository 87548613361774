import React from "react";
import {enqueueSnackbar} from 'notistack';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import {useDispatch, useSelector} from 'react-redux';
import {
  selectAllStrategies,
  selectStrategiesNotOwned
} from 'redux/strategy/strategySlice';
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BoardTable from "components/atoms/BoardTable/BoardTable";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Chip, Grid, Link, TableSortLabel} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import {Link as RouterLink} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import {routes} from "routes";
import Tooltip from '@mui/material/Tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  createWatchList,
  deleteWatchList,
  selectAllWatchListStrategyIds,
  selectStrategyToWatchListMapping
} from "redux/watchlist/watchListSlice";
import {createLinkedAccount} from "redux/linkedAccount/linkedAccountSlice";



export default function LeaderBoard() {
  const dispatch = useDispatch();
  const allStrategies = useSelector(selectStrategiesNotOwned);
  const watchedStrategyIds = useSelector(selectAllWatchListStrategyIds);
  const strategyToWatchListMapping = useSelector(selectStrategyToWatchListMapping);
  const isAuthorised = useSelector(state => state.global.isAuthorised);

  const addWatchList = async (strategyId) => {
    await dispatch(createWatchList({'strategy': strategyId})).unwrap();
    enqueueSnackbar('Strategy has been added to watch list.', { variant: 'success' });
  }

  const removeWatchList = async (watchListId, ) => {
    await dispatch(deleteWatchList(watchListId)).unwrap();
    enqueueSnackbar('Strategy has been removed from watch list.', { variant: 'success' });
  }

  return (
    <>
      <PageHeader text1={"Leader"} text2={"Board"}  description={"All performance results are hypothetical. Trading is risky and you can lose money."}/>

      <BoardTable>
        <TableHead>
          <TableRow>
            <TableCell>Strategy</TableCell>
            <TableCell align="center"><TableSortLabel active={true} direction="desc">Minimum Capital</TableSortLabel></TableCell>
            <TableCell align="center"><TableSortLabel>Profit Factor</TableSortLabel></TableCell>
            <TableCell align="center"><TableSortLabel>Worst Drawdown</TableSortLabel></TableCell>
            <TableCell align="center"><TableSortLabel>Annual Return</TableSortLabel></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allStrategies.map((strategy) => (
          <TableRow key={`strategy-${strategy.id}`} >
            <TableCell className="light2" >
              <Grid container >
                <Grid item md={12} lg={6}>
                  <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                    <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                  </RouterLink>
                  {strategy.trade_type.map((value, index) => (
                    <Chip key={`strategy-${strategy.id}-type-${index}`} label={value.toUpperCase()} size="small" variant="outlined" sx={{mr: 0.5}}/>
                  ))}
                </Grid>
                <Grid item md={12} lg={6} >
                  <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}}  mt={{xs: 1, lg: 0}} >
                    { isAuthorised &&
                      <>
                        <Button
                        variant={watchedStrategyIds.includes(strategy.id) ? "contained" : "outlined"}
                        size="small"
                        color={watchedStrategyIds.includes(strategy.id) ? "success" : "primary"}

                        onClick={() => {
                                if (watchedStrategyIds.includes(strategy.id)) {
                                    const watchListId = strategyToWatchListMapping[strategy.id];
                                    removeWatchList(watchListId);
                                } else {
                                    addWatchList(strategy.id);
                                }
                            }}
                        disabled={strategy.owned}
                        >
                          Watch List
                        </Button>
                      </>

                    }
                    &nbsp;
                    <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                      <Button variant="outlined" size="small" > Details </Button><br/>
                    </RouterLink>
                  </Box>
                </Grid>
              </Grid>

              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="largeText" color="secondary.dark" >$35,000</Typography><br/>
                <Typography variant="smallText" >Minimum Capital Req.</Typography>
              </TableCell>
              <TableCell  align="center" className="light">
                <Typography variant="largeText" color="secondary.dark" >6.24</Typography><br/>
                <Typography variant="smallText" >Profit Factor</Typography>
              </TableCell>
              <TableCell  align="center" className="light">
                <Typography variant="largeText" color="secondary.dark" >(27.7%)</Typography><br/>
                <Typography variant="smallText" >Worst Drawdown</Typography>
              </TableCell>
              <TableCell  align="center" className="light">
                <Typography variant="largeText" color="secondary.dark" >+53.0%</Typography><br/>
                <Typography variant="smallText" ><Box element="span" sx={{ fontWeight: "500"}} >Annual Return</Box>since Sep 23, 2020</Typography>
              </TableCell>
          </TableRow>
          ))}
        </TableBody>
      </BoardTable>
    </>
  )
}