import React from 'react'
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import {useAuth0} from "@auth0/auth0-react";
import authAxios from "utils/authAxios";
import CircularProgress from '@mui/material/CircularProgress';
import {setAuthorised} from "redux/global/globalSlice";
import {Box} from "@mui/system";
import Logo from "images/TPP_Logo_Rev.svg";
import {fetchUserProfile} from "redux/userProfile/userProfileSlice";
import {fetchWatchList} from "redux/watchlist/watchListSlice";
import Typography from "@mui/material/Typography";



const AuthProvider = ({children}) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const isAuthorised = useSelector(state => state.global.isAuthorised);
  const userProfile = useSelector(state => state.userProfile.data);

  useEffect(() => {
     (async () => {
       if(isAuthenticated){
         const accessToken = await getAccessTokenSilently({
           authorizationParams: {audience: window.REACT_APP_BACKEND_URL}
         });
         if(!_.isEmpty(accessToken)){
           authAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
           dispatch(setAuthorised(true))
           dispatch(fetchUserProfile());
         }

       }
     })();
  }, [isAuthenticated, isAuthorised])

  return (<>
    { isLoading || isAuthenticated && !isAuthorised || isAuthorised && userProfile === null?
      <>
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#181720', }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <img src={Logo} alt="Portfolio Platform" width={160} style={{ marginLeft: "8px", marginRight: "8px", marginBottom: "20px" }} />
                <CircularProgress color="secondary" />
            </Box>
        </Box>
      </>
      : children}
  </>)
}


export default AuthProvider;
