import React from "react";
import _ from "lodash";
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-mui';
import {Card, Grid} from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {selectKYCStatus, submitKYC} from "redux/kyc/kycSlice";
import {enqueueSnackbar} from 'notistack';
import VerifiedIcon from '@mui/icons-material/Verified';
import UploadIcon from '@mui/icons-material/Upload';
import CircularProgress from '@mui/material/CircularProgress';
import {selectCurrentUserProfile} from "redux/userProfile/userProfileSlice";


const Kyc = () => {

  const dispatch = useDispatch();
  const KYCStatus = useSelector(selectKYCStatus);
  const userProfile = useSelector(selectCurrentUserProfile);

  const KycSchema = Yup.object().shape({
    forename: Yup.string().required('Fist Name is required'),
    surname: Yup.string().required('Last Name is required'),
    title: Yup.string().required('Title is required'),
  });
  //

  const initialValues = {
    forename: userProfile.first_name,
    surname: userProfile.last_name,
    email: userProfile.email,
    title: '',
  }

  const handleKYCSubmit = async (values) => {
    await dispatch(submitKYC(values)).unwrap();
    enqueueSnackbar("KYC verification request has been submitted", { variant: 'success' })
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6}>
        <Card sx={{p: 3}}>
          <Typography color="secondary.dark" fontWeight={500}>KYC Verification</Typography>

          {_.isEmpty(KYCStatus) ? <Formik initialValues={initialValues} onSubmit={handleKYCSubmit} validationSchema={KycSchema}>
            {({isSubmitting, errors, touched}) => (
              <Form >
                <Grid container spacing={3} sx={{mt: 1}}>
                  <Grid item xs={12} sm={6}>
                    <Field component={TextField} id="forename" name="forename" label="First Name" fullWidth margin="normal" size="normal" disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field component={TextField} id="surname" name="surname" label="Last Name" fullWidth margin="normal" size="normal" disabled />
                  </Grid>
                </Grid>
                <Field component={TextField} id="title" name="title" label="Title" fullWidth margin="normal" size="normal" helperText="Mr, Mrs, Ms, Miss, Dr, etc" />
                <Field component={TextField} id="email" name="email" label="Email" margin="normal" size="normal" disabled fullWidth/>
                <Typography align="right">
                    <Button sx={{mt: 2}} type="submit" variant="contained" color="secondary"  endIcon={isSubmitting? <CircularProgress size={20} color="secondary" /> :  <UploadIcon/>} disabled={isSubmitting}> REQUEST KYC VERIFICATION </Button>
                </Typography>
              </Form>
              )}
              </Formik>
            :
              <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
                STATUS:
                <Typography
                  variant="span"
                  color={KYCStatus.status === "pending" ? "light.main" : "success.dark"}
                  fontWeight={500} sx={{verticalAlign: 'middle', display: 'inline-flex'}}
                >
                  {KYCStatus.status.toUpperCase()} &nbsp;{KYCStatus.status === "verified" && <VerifiedIcon/>}
                </Typography>
              </Box>
          }

        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
          <div>
            <Typography variant="h3" >Due diligence and KYC Verification</Typography>
            <Box sx={{mb: 3, mt: 3}}>
            Please note that your request will be forwarded to our Compliance department. They will process our regulatory Due diligence and KYC verification.<br/>
            This process is essential to verify your identity and maintain the integrity of our services. Subsequently, you will receive an email from our Back Office.<br/>
            This email will contain detailed instructions on how to proceed with the verification process.<br/>
            </Box>
          <ol>
            <li>Follow the instructions in the email to complete your application. This may include submitting personal information and relevant documents as required by our Compliance department.</li>
            <li>Once you submit your information, our Compliance will review your submission. The review process usually takes 24 to 48 hours upon receipt of your documents, after which your account status will be updated.</li>
            <li>Upon successful verification, you will be notified, and your account will be fully activated.</li>
          </ol>
          </div>
      </Grid>
    </Grid>

  );
};

export default Kyc;
