import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import {styled} from "@mui/material/styles";


const StyledTableContainer = styled(TableContainer)(
  ({theme}) => ({
    '& table': {
      borderCollapse: 'separate' ,
      borderSpacing: '10px',
      marginLeft: '-10px',
      marginRight: '-10px',
    },

    '& th' : {
      lineHeight: '1.4rem',
    },

    '& th, & td': {
      borderBottomWidth: 0,
      padding: '5px 10px',
      borderSpacing: '10px',
    },

    '& .light': {
      backgroundColor: theme.main.bgLight,
      border: `1px ${theme.palette.light.main} solid`,
      borderWidth: '1px',
      borderRadius: '10px',
    },

    '& .light2': {
      backgroundColor: theme.main.bgLight2,
      border: `1px ${theme.main.bgDark} solid`,
      borderWidth: '1px',
      borderRadius: '10px',
    }
  }),
)


export default function BoardTable({children, ...props}) {
  return (
    <StyledTableContainer >
      <Table sx={{ minWidth: 650 }} {...props}>
        {children}
      </Table>
    </StyledTableContainer>
  );
}