import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchLinkedAccounts = createAsyncThunk('linkedAccount/fetchLinkedAccounts', async () => {
  return authAxios.get('/linked-accounts/linked-accounts/').then(response => response.data);
});

export const createLinkedAccount = createAsyncThunk('linkedAccount/createLinkedAccount', (linkedAccountData) => {
  return authAxios.post('/linked-accounts/linked-accounts/', linkedAccountData).then(response => response.data);
});

export const updateLinkedAccount = createAsyncThunk('linkedAccount/updateLinkedAccount', ({ linkedAccountId, linkedAccountData }) => {
  return authAxios.put(`/linked-accounts/linked-accounts/${linkedAccountId}/`, linkedAccountData).then(response => response.data);
});

export const deleteLinkedAccount = createAsyncThunk('linkedAccount/deleteLinkedAccount', (linkedAccountId) => {
  return authAxios.delete(`/linked-accounts/linked-accounts/${linkedAccountId}/`).then(response => response.data);
});

export const linkedAccountsAdapter = createEntityAdapter();

// Slice
export const linkedAccountSlice = createSlice({
  name: 'linkedAccount',
  initialState: linkedAccountsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
  },
 extraReducers: (builder) => {
    builder
      .addCase(fetchLinkedAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLinkedAccounts.fulfilled, (state, action) => {
        linkedAccountsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchLinkedAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createLinkedAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLinkedAccount.fulfilled, linkedAccountsAdapter.addOne)
      .addCase(createLinkedAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateLinkedAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLinkedAccount.fulfilled, linkedAccountsAdapter.upsertOne)
      .addCase(updateLinkedAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteLinkedAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLinkedAccount.fulfilled, linkedAccountsAdapter.removeOne)
      .addCase(deleteLinkedAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectById: selectLinkedAccountById,
  selectAll: selectAllLinkedAccounts,
} = linkedAccountsAdapter.getSelectors((state) => state.linkedAccount);


export const selectLinkedAccountByBrokerID = (state, brokerID) => {
    return Object.values(state.linkedAccount.entities).filter(linked_account => linked_account.broker === brokerID);
};

export default linkedAccountSlice.reducer;