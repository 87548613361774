import React from 'react';
import { MaterialDesignContent, SnackbarProvider, closeSnackbar, useSnackbar } from 'notistack';
import {styled, useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";


const NotificationProvider = ({ children }) => {
  const theme = useTheme();

  const StyledProvider = styled(SnackbarProvider)(() => ({
    '& #notistack-snackbar': {
        fontSize: '14px !important',
    },

  }));

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.main,
      '& .MuiButton-root': {
        color: theme.palette.primary.main,
        fontSize: '14px !important',
      }
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main,
      '& .MuiButton-root': {
        color: '#fff',
        fontSize: '14px !important',
      }
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main,
      '& .MuiButton-root': {
        color: '#fff',
        fontSize: '14px !important',
      }
    },
  }));

  return (
    <>
    <StyledProvider
      autoHideDuration={3000}
      preventDuplicate={true}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(snackbarId, message, options) => (
        <Button onClick={() => closeSnackbar(snackbarId)} color={options}>
          Dismiss
        </Button>
      )}
    >
       </StyledProvider>
      {children}

      </>
  );
};

export default NotificationProvider;
