import axios from 'axios'

const authAxios = axios.create({
    baseURL: window.REACT_APP_BACKEND_URL
});


authAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {

      if (error.response.status === 401) {
        const logoutURL = `https://${window.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${window.REACT_APP_AUTH0_CLIENT_ID}&returnTo=${encodeURIComponent(window.location.origin)}`;
          setTimeout(() => {
            window.location.assign(logoutURL);
          }, 2000);
      }

      throw new Error(JSON.stringify(
        {message: error.message, data: error.response.data, code: error.response.status }
      ));
    }
    throw error;
  }
);


export default authAxios;

