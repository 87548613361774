import * as React from "react";
import {Box} from '@mui/system';
import {CssBaseline} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import MainMenu from 'components/organisms/Menu/MainMenu';
import mainStyles from 'theme/mainStyles'
import UserMenu from "components/organisms/Menu/UserMenu";
import MobileMenu from "components/organisms/Menu/MobileMenu";

export default function MainTemplate({children, simpleTemplate}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
  <Box id="main">
    <CssBaseline/>
    {mainStyles}
    <Box sx={{display: {xs: 'block', sm: 'none'}}}>
      <MobileMenu active={mobile}/>
    </Box>

    <Box sx={{display: mobile ? 'block' : 'flex'}}>
      <Box sx={{display: {xs: 'none', sm: 'block'}}}>
        <MainMenu/>
      </Box>
      <Box component="main" sx={{flexGrow: 1,}}>
        <Box sx={{display: {xs: 'none', sm: 'block'}}}>
          <UserMenu/>
        </Box>
        <Box sx={{p: 3}}>
          {children}
        </Box>
      </Box>
    </Box>
  </Box>
  )
}
