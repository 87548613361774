import GlobalStyles from "@mui/material/GlobalStyles";

const mainStyles = <GlobalStyles styles={(theme) => ({
    html: {
      fontSize: "62.5%",
      height: "100%"
    },

    body: {
      backgroundColor: theme.main.bgLight
    },

    a: {
      cursor: "pointer",
      color: theme.palette.secondary.dark,

      '&:hover > .MuiTypography-root': {
        color: theme.palette.secondary.main,
        transition: "color 0.2s ease",
      },
      textDecoration: "none"
    },


  })} />
    
    // *, *::before, *::after {
    //   box-sizing: border-box;
    //   -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
    // }
    //
    // html {
    //   font-size: 62.5%;
    //   height: 100%;
    // }
    //
    // body {
    //   height: 100%;
    //   font-size: 1.8rem;
    //   background-color: ${props => props.isLogin? '': '#f8f8f8'};
    // }

export default mainStyles