import { CardElement } from '@stripe/react-stripe-js';
import { useTheme } from '@mui/material/styles';

function StyledCardComponent({onCardChange}) {
    const theme = useTheme();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: theme.palette.text.primary,
                fontSize: '16px',
                '::placeholder': {
                    color: theme.palette.text.secondary,
                },
            },
            invalid: {
                color: theme.palette.error.main,
                iconColor: theme.palette.error.main,
            },
        },
        hidePostalCode: true,
    };

    return (
        <div style={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1.5),
            marginBottom: theme.spacing(2)
        }}>
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onCardChange} />
        </div>
    );
}

export default StyledCardComponent;