import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchKYC = createAsyncThunk('kyc/fetchKYC', async () => {
  return authAxios.get('/kyc/').then(response => response.data);
});

export const submitKYC = createAsyncThunk('kyc/createKYC', async (KycData) => {
  return authAxios.post('/kyc/', {'data': KycData}).then(response => response.data);
});

export const submitAnonymousKYC = createAsyncThunk('kyc/createKYC', async (KycData) => {
  console.log(KycData);
  return authAxios.post('/kyc/anonymous', {'data': KycData}).then(response => response.data);
});




// Slice
export const kycSlice = createSlice({
  name: 'kyc',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKYC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchKYC.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchKYC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(submitKYC.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitKYC.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(submitKYC.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectKYCStatus = (state) => state.kyc.data;

export default kycSlice.reducer;
