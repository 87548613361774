import React from "react";
import {styled, useTheme} from '@mui/material/styles';
import {Box} from "@mui/system";


const StyledPaper = styled(Box)(
  ({theme, variant}) => ({
    border: `1px ${theme.palette.light.main} solid`,
    backgroundColor: theme.main.bgLight,
    borderRadius: '10px',

    ...(variant === "light2" && {
      backgroundColor: theme.main.bgLight2,
      borderColor: theme.main.bgDark,

    }),
    ...(variant === "dark" && {
      backgroundColor: theme.main.bgDark,
      borderColor: theme.main.bgDark,
      color: theme.text.light,

    }),

  }),
)

export const Paper = ({children,...props}) => {
  return <StyledPaper sx={{p: 2}} {...props} >{children}</StyledPaper>
}



