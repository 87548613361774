import React, { useState } from 'react';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogTitle, DialogContent, Box, Typography, Button, DialogActions} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {ReactComponent as EmailIcon} from "images/Email.svg"
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {selectCurrentUserProfile} from "redux/userProfile/userProfileSlice";



const ContactFormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  subject: Yup.string().required('Required'),
  message: Yup.string().min(20, 'Too short!').max(500, 'Too long!').required('Required'),
});

const ContactForm = ({ initialValues, onSubmit }) => (
  <Formik initialValues={initialValues} validationSchema={ContactFormSchema} onSubmit={onSubmit}>
    {({ isSubmitting }) => (
      <Form>
        <DialogContent >
          <Box display="flex" flexDirection="column" gap={2}>
            <Field component={TextField} name="name" label="Name" variant="outlined" fullWidth InputProps={{ readOnly: true }} disabled  />
            <Field component={TextField} name="phoneNumber" label="Phone Number" variant="outlined" fullWidth InputProps={{ readOnly: true }} disabled />
            <Field component={TextField} name="subject" label="Subject" variant="outlined" fullWidth />
            <Field component={TextField} name="message" label="Message" variant="outlined" fullWidth multiline rows={4} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ mr: 2, mb: 1, mt: -1}}>
            <Button type="submit" variant="contained" color="secondary" endIcon={<MailOutlineIcon/>} disabled={isSubmitting}>
              Send
            </Button>
          </Box>
        </DialogActions>
      </Form>
    )}
  </Formik>
);

const ContactFormModal = ({mobile = false}) => {
  const [open, setOpen] = useState(false);
  const userProfile = useSelector(selectCurrentUserProfile);

  const initialValues = {
    name: `${userProfile.first_name} ${userProfile.last_name}`,
    phoneNumber: `(+${userProfile.country_code.phone}) ${userProfile.mobile_phone}`,
    subject: '',
    message: '',
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      console.log('Submitted:', values);
      // showSnackbar("success", "Message has been sent.")
      resetForm();
      setSubmitting(false);
      handleClose();
    }, 1000);

  };

  return (
    <>
      { }
      <Tooltip title="Contact Us">
        {mobile ?
        <IconButton size="small" sx={{ mr: 1 }} color="inherit" onClick={handleOpen}>
           <MailOutlineIcon />
        </IconButton>:
        <IconButton size="small" sx={{ mr: 1 }}  onClick={handleOpen} >
           <EmailIcon width="48px"/>
        </IconButton>}
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth >
        <DialogTitle> Contact Us </DialogTitle>
        <ContactForm initialValues={initialValues} onSubmit={handleSubmit} />
      </Dialog>
    </>
  );
};

export default ContactFormModal;
