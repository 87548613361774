import React from "react";
import _ from "lodash"
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Chip} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from 'notistack';
import {
  deleteSubscription,
  fetchSubscriptionDetails,
  selectSubscriptionByStrategyId
} from "redux/subscription/subscriptionSlice";
import {selectSubscribedStrategies} from "redux/strategy/strategySlice";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';


const StyledTableContainer = styled(TableContainer)(
  ({theme}) => ({
    '& > table > tbody > tr:nth-child(odd)': {
      '> td, > th': {
        borderBottom: 'unset'
      }
    },

  }),
)

function Row(props) {
  const dispatch = useDispatch();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const subscription = useSelector((state) => selectSubscriptionByStrategyId( state, row.id))[0];
  const details = useSelector(state => state.subscription.subscriptionDetails[subscription.id])

  const handleRowClick = async (values) => {
    if(!details){
      setLoading(true)
      await dispatch(fetchSubscriptionDetails(subscription.id)).unwrap();
      setLoading(false)
    }
    setOpen(!open);
  }

  const handleUnsubscribe = async (values) => {
     setLoading(true)
     await dispatch(deleteSubscription(subscription.id)).unwrap();
     enqueueSnackbar('Subscription has been cancelled.', { variant: 'success' });
  }

  return (
    <React.Fragment>
      <TableRow >
        <TableCell width={50}>
          {loading? <CircularProgress size={34} color="secondary"/> :
            <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleRowClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>
        <TableCell component="th" scope="row"> {row.name} </TableCell>
        <TableCell align="center">{row.charge}</TableCell>
        <TableCell align="right">
          <Chip label={subscription.is_active ? "Active" : "Inactive"} color={subscription.is_active  ? "success" : "warning"} size="small" />
        </TableCell>
      </TableRow>
      {details && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin:1 }}>
                  <p>Created: {new Date(details.created * 1000).toLocaleDateString()}</p>
                  <p>Next Billing Date: {new Date(details.current_period_end * 1000).toLocaleDateString()}</p>
                <Typography variant="h5" gutterBottom component="div" sx={{mt: 3}}>
                  Billing History
                </Typography>
                <Table size="small"  sx={{mt: 3, mb: 3}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell align="right"><Box sx={{mr: 1}}>Paid</Box></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.payment_history.map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell component="th" scope="row">
                          {new Date(payment.created * 1000).toLocaleDateString()}
                        </TableCell>
                        <TableCell>${payment.amount / 100}</TableCell>
                        <TableCell>{payment.currency.toUpperCase()}</TableCell>
                        <TableCell align="right">
                          <Chip label={payment.paid ? "Paid" : "Not Paid"} color={payment.paid  ? "success" : "warning"} size="small" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Typography align="right">
                    <Button color="error" variant="contained" sx={{mb: 3}} onClick={() => handleUnsubscribe()}  disabled={loading}>Cancel Subscription</Button>
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}


const Subscriptions = () => {

  const subscribedStrategies = useSelector(selectSubscribedStrategies)


  return (
    <StyledTableContainer component={Paper}>
      <Box sx={{p: 3}}>
        <Typography color="secondary.dark" fontWeight={500}>List of subscribed strategies</Typography>
      </Box>
      {_.isEmpty(subscribedStrategies) ?
        <>
          <Typography sx={{p: 3}}> No subscriptions </Typography>
        </> :
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Strategy Name</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="right"><Box sx={{mr: 1}}>Status</Box></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribedStrategies.map((strategy) => (
              <Row
                key={strategy.id}
                row={strategy}
              />
            ))}
          </TableBody>
        </Table>

      }
    </StyledTableContainer>
  );
};

export default Subscriptions;
