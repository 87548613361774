import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const mainTheme = createTheme({
  main: {
    bgLight: '#f2f2f2',
    bgLight2: '#E6E6E6',
    bgDark: '#181720'
  },
  typography: {
    fontFamily: 'Barlow, Arial',
    htmlFontSize: 10,
    h1: {
      fontSize: '3.5rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '3.5rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '2.5rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '2.0rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.8rem',
      fontWeight: 500,
    },
    smallText: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    mediumText: {
      fontSize: '2.0rem',
      fontWeight: 500,
    },
    largeText: {
      fontSize: '3.0rem',
      fontWeight: 600,
    }
  },
  palette: {
    primary: {
      main: '#181720',
    },
    secondary: {
      main: '#b66cf9',
      dark: '#7f4bae',
    },
    success : {
      main: '#bbf9bb',
      dark: '#82ae82',
    },
    light: {
      main: '#c0c0c0'
    },
    info: {
      main: '#181720'
    }
  },
  text: {
    primary: '#999',
    secondary: '#B3B51C',
    light: '#F1F3F4'
  },
  components: {
    MuiChip: {
      styleOverrides: {
        labelSmall: {
          fontSize: '1.1rem',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.25rem',
        },
      },
    },
  }
});
