import React from 'react';
import * as Yup from 'yup';
import {useAuth0} from "@auth0/auth0-react";
import { enqueueSnackbar } from 'notistack';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Autocomplete, CheckboxWithLabel, TextField } from 'formik-mui';
import { FormControl, FormHelperText, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Logo from "images/TPP_Logo_Rev.svg";
import MuiTextField from '@mui/material/TextField';
import countries from 'utils/countries';
import { updateUserProfile } from "redux/userProfile/userProfileSlice";
import {submitKYC} from "redux/kyc/kycSlice";


const ProfileBoxWrapper = styled(Box)(
  ({theme}) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: '100vh',
      backgroundColor: '#000',
    }
  ),
)

const ProfileBox = styled(Box)(
  ({theme}) => ({
    maxWidth: '550px',
    padding: '50px',
    backgroundColor: theme.main.bgDark,
    color: theme.palette.light.main,
  }),
)

const StyledTextField = styled(TextField)(
  ({theme}) => ({
    '& .MuiFormLabel-root': {
      backgroundColor: theme.main.bgLight,
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '3px'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.main.bgLight,
    },
    '& label.Mui-focused': {
      color: 'gray',
    },

  }),
)

const StyledField = styled(Field)(
  ({theme}) => ({
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.4rem',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.main.bgLight,
    },

  }),
)

const StyledFormControl = styled(FormControl)(
  ({theme}) => ({
    '& .MuiFormLabel-root': {
      backgroundColor: theme.main.bgLight,
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '3px'
    },
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: `${theme.palette.light.main} !important`
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.4rem',
    }
  }),
)

const StyledCheckboxWithLabel = styled(CheckboxWithLabel)(
  ({theme}) => ({
    '&.MuiCheckbox-root': {
      color: theme.main.bgLight,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  }),
)

const StyledButton = styled(Button)(
  ({theme}) => ({
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.light,
    }
  }),
)


const InitialUserProfile = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const initialValues = {
    first_name: '',
    last_name: '',
    mobile_phone: '',
    country_code: null,
    tac: false,
    marketing: false,
  };

  const UserProfileSchema = Yup.object().shape({
    first_name: Yup.string().required('Fist Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    mobile_phone: Yup.string().required('Mobile Phone Number is required'),
    tac: Yup.boolean().oneOf([true],'You have to accept Privacy Policy and Terms and Conditions'),
    country_code: Yup.object().shape({
        code: Yup.string().required("Is required"),
        label: Yup.string().required("Is required"),
        phone: Yup.string().required("Is required")
      }).required("Field is required")
  });

  const handleSubmit = async (values) => {
    values.email = user.email;
    await dispatch(updateUserProfile(values)).unwrap();
    enqueueSnackbar('User profile has been created successfully.', { variant: 'success' });
  }

  return (
    <ProfileBoxWrapper>
      <ProfileBox>
        <Box mb={3} textAlign="center">
          <img src={Logo} alt="Portfolio Platform" width={200} />
          <Typography variant="h3" color={"white"}>Create Your Profile</Typography>
          <Typography variant="p" >Please provide us with some additional information</Typography>
        </Box>
        <Formik
          sx={{ mt: 3 }}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={UserProfileSchema}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledField component={StyledTextField} name="first_name" label="First Name" fullWidth   />
                </Grid>
                <Grid item xs={12}>
                  <StyledField component={StyledTextField} name="last_name" label="Last Name" fullWidth  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <StyledFormControl fullWidth  >
                    <StyledField
                      name="country_code"
                      component={Autocomplete}
                      options={countries}
                      getOptionLabel={(option: any) => `${option.label} (+${option.phone})`}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                          {...params}
                          name="country_code"
                          error={touched['autocomplete'] && !!errors['autocomplete']}
                          helperText={touched['autocomplete'] && errors['autocomplete']}
                          label="Country Code"
                          variant="outlined"
                        />
                      )}
                    />
                    {touched.country_code && errors.country_code ? (
                        <FormHelperText error>{errors.country_code}</FormHelperText>
                     ) : null}

                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} md={7}>
                  <StyledField component={StyledTextField} name="mobile_phone" label="Mobile Phone Number" fullWidth  />
                </Grid>
                <Grid item xs={12}>
                  <StyledFormControl fullWidth  >
                    <Field
                      type="checkbox"
                      component={StyledCheckboxWithLabel}
                      name="tac"
                      Label={{ label: 'I accept the Privacy Policy and Terms and Conditions' }}
                    />
                     {touched.tac && errors.tac ? (
                        <FormHelperText error>{errors.tac}</FormHelperText>
                     ) : null}
                  </StyledFormControl>
                  <StyledFormControl fullWidth  >
                    <Field
                      type="checkbox"
                      component={StyledCheckboxWithLabel}
                      name="marketing"
                      label="Marketing Communication"
                      Label={{ label: 'I agree to receive marketing communication'}}
                    />
                  </StyledFormControl>
                </Grid>

                <Grid item xs={12} >
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    size="large"
                    fullWidth
                  >
                    Submit
                  </StyledButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ProfileBox>
    </ProfileBoxWrapper>
  );
};

export default InitialUserProfile;
