import {Route, Routes, unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';
import {Provider} from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {createBrowserHistory} from 'history';
import MainTemplate from 'templates/mainTemplate';
import SimpleTemplate from 'templates/simpleTemplate';
import ProtectedComponent from "routes/ProtectedRoute";
import store from 'redux/store';
import {routes} from "routes";
import Account from "views/Account";
import LeaderBoard from "views/LeaderBoard";
import Dashboard from "views/Dashboard/Dashboard";
import WatchList from "views/WatchList";
import AutoTrade from "views/AutoTrade/AutoTrade";
import BrokerAccountList from "views/BrokerAccount/BrokerAccountList";
import BrokerAccountConnect from "views/BrokerAccount/BrokerAccountConnect";
import WebTrader from "views/WebTrader";
import StrategyDetails from "views/Stretegy/StrategyDetails";
import StrategyForm from "views/Stretegy/StrategyForm";
import AuthProvider from "utils/AuthProvider";
import AppProvider from "utils/AppProvider";
import NotificationProvider from "utils/NotificationProvider";
import { ThemeProvider } from '@mui/material/styles';
import {mainTheme} from "theme/mainTheme";
import {ConfirmationProvider} from "utils/ConfirmationProvider";
import AutoTradeForm from "views/AutoTrade/AutoTradeForm";
import KYCAnonymous from "views/KYCAnonymous";
import InitialUserProfile from "views/InitialUserProfile";


const stripePromise = loadStripe(window.REACT_APP_STRIPE_PUBLISHABLE_KEY);
export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};


export default function Root() {
  return (
    <Auth0Provider
        domain={window.REACT_APP_AUTH0_DOMAIN}
        clientId={window.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: window.REACT_APP_BACKEND_URL
        }}
        onRedirectCallback={onRedirectCallback}
    >
      <ThemeProvider theme={mainTheme}>
        <NotificationProvider>
          <Provider store={store}>
            <AuthProvider>
            <AppProvider>
              <Elements stripe={stripePromise}>
                <HelmetProvider>
                  <Helmet titleTemplate="%s | TPP" > </Helmet>
                  <HistoryRouter history={history}>
                    <ConfirmationProvider>
                      <Routes>
                        <Route path="/" exact element={<MainTemplate><LeaderBoard/></MainTemplate>} />
                        <Route path={routes.KYCAnonymous} exact element={<SimpleTemplate><KYCAnonymous/></SimpleTemplate>} />
                        <Route path={routes.userInitialProfile} exact element={<SimpleTemplate><ProtectedComponent component={InitialUserProfile} /></SimpleTemplate>} />
                        <Route path={routes.account} exact element={<MainTemplate><ProtectedComponent component={Account} /></MainTemplate>} /> />
                        <Route path={routes.dashboard} exact element={<MainTemplate><ProtectedComponent component={Dashboard} /></MainTemplate>} /> />
                        <Route path={routes.strategyDetails} exact element={<MainTemplate><StrategyDetails/></MainTemplate>} /> />
                        <Route path={routes.strategyFormCreate} exact element={<MainTemplate><ProtectedComponent component={StrategyForm} /></MainTemplate>} /> />
                        <Route path={routes.strategyFormEdit} exact element={<MainTemplate><ProtectedComponent component={StrategyForm}/></MainTemplate>} /> />
                        <Route path={routes.leaderBoard} exact element={<MainTemplate><LeaderBoard/></MainTemplate>} /> />
                        <Route path={routes.watchList} exact element={<MainTemplate><ProtectedComponent component={WatchList} /></MainTemplate>} /> />
                        <Route path={routes.autoTrade} exact element={<MainTemplate><ProtectedComponent component={AutoTrade} /></MainTemplate>} /> />
                        <Route path={routes.autoTradeFormCreate} exact element={<MainTemplate><ProtectedComponent component={AutoTradeForm} /></MainTemplate>} /> />
                        <Route path={routes.autoTradeFormEdit} exact element={<MainTemplate><ProtectedComponent component={AutoTradeForm} /></MainTemplate>} /> />
                        <Route path={routes.brokerAccountList} exact element={<MainTemplate><ProtectedComponent component={BrokerAccountList}/> </MainTemplate>} /> />
                        <Route path={routes.brokerAccountConnect} exact element={<MainTemplate><ProtectedComponent component={BrokerAccountConnect} /></MainTemplate>} /> />
                        <Route path={routes.webTrader} exact element={<MainTemplate><ProtectedComponent component={WebTrader} /></MainTemplate>} /> />
                      </Routes>
                    </ConfirmationProvider>
                  </HistoryRouter>
                </HelmetProvider>
              </Elements>
          </AppProvider>
          </AuthProvider>
        </Provider>
      </NotificationProvider>
    </ThemeProvider>
  </Auth0Provider>
  )
}
