import React, {createContext, useCallback, useContext, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {DialogContentText} from "@mui/material";

const ConfirmationContext = createContext();

export const useConfirmation = () => {
  return useContext(ConfirmationContext);
};

const ConfirmationModal = ({ title, text, onConfirm, onCancel, maxWidth}) => (
  <Dialog open={true} onClose={onCancel} fullWidth={true} maxWidth={maxWidth}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent style={{opacity: 0.6, margin: 0}}>
      {text}
    </DialogContent>
    <DialogActions sx={{p: 3}}>
      <Button onClick={onCancel} color="primary"> Cancel </Button>
      <Button onClick={onConfirm} color="secondary" variant="contained" > Confirm </Button>
    </DialogActions>
  </Dialog>
);

export const ConfirmationProvider = ({ children }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null,
    maxWidth: 'sm'
  });

  const confirm = useCallback(({ title, message, onConfirm, maxWidth }) => {
    setModal({
      isOpen: true,
      title,
      message,
      onConfirm,
      maxWidth
    });
  }, []);

  const closeModal = useCallback(() => {
    setModal({
      isOpen: false,
      title: '',
      message: '',
      onConfirm: null,
    });
  }, []);

  return (
    <ConfirmationContext.Provider value={confirm}>
      {children}
      {modal.isOpen && (
        <ConfirmationModal
          title={modal.title}
          text={modal.message}
          maxWidth={modal.maxWidth}
          onConfirm={async () => {
            modal.onConfirm && modal.onConfirm();
            closeModal();
          }}
          onCancel={closeModal}
        />
      )}
    </ConfirmationContext.Provider>
  );
};
