import React from "react";
import _ from "lodash";
import {useDispatch, useSelector} from 'react-redux';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import Typography from "@mui/material/Typography";
import BoardTable from "components/atoms/BoardTable/BoardTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Alert, Chip, Grid, Link} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "routes";
import {selectWatchedStrategies} from "redux/strategy/strategySlice";
import {Box} from "@mui/system";
import {enqueueSnackbar} from 'notistack';
import {deleteWatchList, selectStrategyToWatchListMapping} from "redux/watchlist/watchListSlice";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import SettingsDropdown from "components/molecules/SettingsDropdown/SettingsDropdown";
import Tooltip from '@mui/material/Tooltip';
import {createSimulation} from "redux/simulation/simulationSlice";


export default function WatchList() {
  const dispatch = useDispatch();
  const watchListStrategies = useSelector(selectWatchedStrategies);
  const simulatedStrategies = useSelector(state => state.simulation.entities);
  const subscribedStrategies = useSelector(state => state.subscription.entities);
  const strategyToWatchListMapping = useSelector(selectStrategyToWatchListMapping);

  const removeWatchList = async (watchListId) => {
    await dispatch(deleteWatchList(watchListId));
    enqueueSnackbar(`Strategy has been removed from watch list.`, { variant: 'success' })
  }

  const handleStartSimulation = async (strategyId, strategyName) => {
    await dispatch(createSimulation({'strategy': strategyId})).unwrap();
    enqueueSnackbar(`Simulation for "${strategyName}" has been started.`, { variant: 'success' })
  }

  return (
    <>
      <PageHeader text1={"Watch"} text2={"List"}  description={"You can follow these strategies automatically in your brokerage account."}/>

      {_.isEmpty(watchListStrategies) ?
        <Box sx={{mt: 4}}>
          <Alert severity="info"> You have no strategies in your watch list. Please <Link component={RouterLink} to={routes.leaderBoard}>discover strategies</Link> and add to your watch list.</Alert>
        </Box> :

        <BoardTable sx={{ pt: 5}} >

        <TableBody >
          {watchListStrategies.map((strategy) => {
            const simulation = Object.values(simulatedStrategies).find(sim => sim.strategy === strategy.id);
            const subscription = Object.values(subscribedStrategies).find(sub => sub.strategy === strategy.id);

            return (
            <TableRow key={`strategy-${strategy.id}`}>
              <TableCell className="light2" sx={{width: {md: 'auto', lg: '30%'}}}>
                <Grid container sx={{pt: 1, pb: 1}}>
                  <Grid item md={12} lg={6}>
                    <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                      <Typography variant="h4" sx={{mb: 1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                    </RouterLink>
                    {strategy.trade_type.map((value, index) => (
                      <Chip key={`strategy-${strategy.id}-type-${index}`} label={value.toUpperCase()} size="small"
                            variant="outlined" sx={{mr: 0.5}}/>
                    ))}
                  </Grid>
                  <Grid item md={12} lg={6}>
                    <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>

                      <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                        <Button color="primary" variant="outlined" size="small"> Details </Button>
                      </RouterLink>
                    </Box>
                  </Grid>

                </Grid>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">14</Typography><br/>
                <Typography variant="smallText">Num Trades</Typography>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">9</Typography><br/>
                <Typography variant="smallText">Win Trades</Typography>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">15</Typography><br/>
                <Typography variant="smallText">Num Months</Typography>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">5</Typography><br/>
                <Typography variant="smallText">Win months</Typography>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">(12.5%)</Typography><br/>
                <Typography variant="smallText">Max Drawdown</Typography>
              </TableCell>
              <TableCell align="center" className="light">
                <Typography variant="mediumText">3.0%</Typography><br/>
                <Typography variant="smallText">Annual Return</Typography>
              </TableCell>
              <TableCell align="center">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                { subscription ?
                  <>
                    {subscription.autotrading_status === 'inactive' && <Chip label="INACTIVE" size="small" color="warning" sx={{mb: 0.5}} />}
                    {subscription.autotrading_status === 'awaiting_approval' && <Chip label="AWAITING APPROVAL" size="small" color="default" sx={{mb: 0.5}} />}
                    {subscription.autotrading_status === 'live' && <Chip label="LIVE" size="small" color="success" sx={{mb: 0.5}} />}
                    <Chip label="SUBSCRIBED" size="small" color="success" sx={{mb: 0.5}}/>
                  </>
                  : simulation ? <Chip label="SIMULATION" size="small" color="secondary" sx={{mb: 0.5}} /> : null
                }
                  </Box>
              </TableCell>
              <TableCell align="right">

                <SettingsDropdown>
                  {!simulation && !subscription ? <MenuItem onClick={() => handleStartSimulation(strategy.id, strategy.name)}>Start Simulation </MenuItem> : <></>}
                  {simulation && !subscription ? <MenuItem component={RouterLink} to={routes.autoTrade}>Manage Simulation &nbsp; (AutoTrade) </MenuItem> : <></>}
                  {!subscription ?  <MenuItem component={RouterLink} to={routes.strategyDetails.replace(":strategyId", strategy.id)}>Subscribe to Strategy</MenuItem> : <></>}

                  {subscription ?
                    subscription.autotrading_status === 'inactive' ?
                      <MenuItem component={RouterLink} to={routes.autoTrade} >Change to Live &nbsp; (AutoTrade)</MenuItem> :
                      <MenuItem component={RouterLink} to={routes.autoTrade} >Manage Trading &nbsp; (AutoTrade)</MenuItem>
                    :
                    <Tooltip title="You have to subscribe to enable AutoTrading" >
                      <span sx={{mb: 1}}> <MenuItem disabled>Change to Live &nbsp; (AutoTrade)</MenuItem> </span>
                    </Tooltip>
                  }

                  {subscription ? <MenuItem component={RouterLink} to={`${routes.account}?tab=subscriptions`}>Manage Subscription</MenuItem> : <></> }
                  <Divider sx={{mt: 1, mb: 1}}/>
                  <MenuItem onClick={() => {
                    const watchListId = strategyToWatchListMapping[strategy.id];
                    removeWatchList(watchListId)
                  }}
                  >Remove from Watch List
                  </MenuItem>
                </SettingsDropdown>
              </TableCell>
            </TableRow>
            )
          })}

        </TableBody>
      </BoardTable>
      }
    </>
  )
}