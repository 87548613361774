import React from "react";
import { Helmet } from 'react-helmet-async';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const PageHeader = ({text1, text2='', description='', ...props}) => (
  <>
    <Box {...props}>
      <Helmet><title>{props.title || `${text1} ${text2}`}</title></Helmet>
      <Typography variant="h1" > <Box component="span" sx={{ fontWeight: 600}}>{text1}</Box> {text2} </Typography>
      {description && <Typography color={"secondary.dark"} fontWeight={500}>{description}</Typography>}
    </Box>
  </>
)