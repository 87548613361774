import { createAsyncThunk, createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";
import {selectAllSubscriptionStrategyIds} from "redux/subscription/subscriptionSlice";
import {selectAllWatchListStrategyIds} from "redux/watchlist/watchListSlice";
import {selectAllSimulationStrategyIds} from "redux/simulation/simulationSlice";

// Thunks
export const fetchStrategies = createAsyncThunk('strategy/fetchStrategies', async () => {
  return authAxios.get(`/strategy/strategies/`).then(response => response.data);
});

export const createStrategy = createAsyncThunk('strategy/createStrategy', (strategyData) => {
  return authAxios.post('/strategy/strategies/', strategyData).then(response => response.data);
});

export const updateStrategy = createAsyncThunk('strategy/updateStrategy', ({strategyId, strategyData}) => {
  return authAxios.put(`/strategy/strategies/${strategyId}/`, strategyData).then(response => response.data);
});

export const deleteStrategy = createAsyncThunk('strategy/deleteStrategy', (strategyId) => {
  return authAxios.delete(`/strategy/strategies/${strategyId}/`).then(response => response.data);
});


export const togglePublicState = createAsyncThunk('strategy/togglePublicState', (strategyId) => {
  return authAxios.patch(`/strategy/strategies/${strategyId}/toggle-public/`).then(response => response.data);
});

// First, create the adapter
const strategiesAdapter = createEntityAdapter();

// Slice
export const strategySlice = createSlice({
  name: 'strategy',
  initialState: strategiesAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrategies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStrategies.fulfilled, (state, action) => {
        strategiesAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchStrategies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createStrategy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStrategy.fulfilled, (state, action) => {
        strategiesAdapter.addOne(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateStrategy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStrategy.fulfilled, (state, action) => {
        strategiesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
        state.loading = false;
        state.error = null;
      })
      .addCase(updateStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteStrategy.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteStrategy.fulfilled, (state, action) => {
        strategiesAdapter.removeOne(state, action.payload.id);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteStrategy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(togglePublicState.fulfilled, (state, action) => {
        const strategy = state.entities[action.meta.arg];
        if (strategy) {
            strategy.public = !strategy.public;
        }
    });
  },
});

// Generate selectors
export const {
  selectById: selectStrategyById,
  selectAll: selectAllStrategies,
} = strategiesAdapter.getSelectors((state) => state.strategy);

export const selectOwnedStrategies = createSelector(
  (state) => state.strategy.entities,
  (entities) => Object.values(entities).filter(strategy => strategy.owned === true)
)

export const selectStrategiesNotOwned = createSelector(
  (state) => state.strategy.entities,
  (entities) => Object.values(entities).filter(strategy => strategy.owned !== true)
)

export const selectWatchListStrategies = createSelector(
  (state) => state.strategy.entities,
  (entities) => Object.values(entities).filter(strategy => strategy.watch_list === true)
)

export const selectSubscribedStrategies = createSelector(
    [selectAllStrategies, selectAllSubscriptionStrategyIds],
    (strategies, subscribedIds) => {
        return strategies.filter(strategy => subscribedIds.includes(strategy.id));
    }
);

export const selectNonSubscribedStrategies = createSelector(
  [selectAllStrategies, selectAllSubscriptionStrategyIds],
  (strategies, subscribedIds) => {
    return strategies.filter(strategy => !subscribedIds.includes(strategy.id));
  }
);

export const selectWatchedStrategies = createSelector(
  [selectAllStrategies, selectAllWatchListStrategyIds],
  (strategies, watchedIds) => {
    return strategies.filter(strategy => watchedIds.includes(strategy.id));
  }
);

export const selectSimulatedStrategies = createSelector(
  [selectAllStrategies, selectAllSimulationStrategyIds],
  (strategies, simulatedIds) => {
    return strategies.filter(strategy => simulatedIds.includes(strategy.id));
  }
);

export const selectSubscribedStrategiesWithSubscription = createSelector(
  selectSubscribedStrategies,
  (state) => state.subscription.entities,
  (strategies, subscriptions) => {
    return strategies.map(strategy => ({
      ...strategy,
      subscription: subscriptions[strategy.id]
    }));
  }
);

export default strategySlice.reducer;
