import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import {Button, Card, FormControl, FormHelperText, Grid} from '@mui/material';
import {Autocomplete, CheckboxWithLabel, TextField} from 'formik-mui';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import Divider from "@mui/material/Divider";
import * as Yup from 'yup';
import {selectCurrentUserProfile, updateUserProfile} from "redux/userProfile/userProfileSlice";
import {useAuth0} from "@auth0/auth0-react";
import InputAdornment from '@mui/material/InputAdornment';
import countries from "utils/countries";
import MuiTextField from '@mui/material/TextField';
import {enqueueSnackbar} from 'notistack';

const UserProfileForm = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const userProfile = useSelector(selectCurrentUserProfile);

  const initialValues = userProfile;

  const UserProfileSchema = Yup.object().shape({
    first_name: Yup.string().required('Fist Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    mobile_phone: Yup.string().required('Mobile Phone Number is required'),
    country_code: Yup.object().shape({
        code: Yup.string().required("Is required"),
        label: Yup.string().required("Is required"),
        phone: Yup.string().required("Is required")
      }).required("Field is required"),
    address_1: Yup.string().required('Address Line 1 is required'),
    postal_code: Yup.string().required('Zip / Postal Code is required'),
  });

    const handleSubmit = async (values) => {
      await dispatch(updateUserProfile(values)).unwrap();
      enqueueSnackbar('User profile has been updated successfully.', { variant: 'success' });
  }

  return (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Card sx={{p: 3}}>
            <Box sx={{mb: 3}}>
              <Typography color="secondary.dark" fontWeight={500}>Non strategy-manager information. <br/>This is not part of the information we verify for Strategy Managers.</Typography>
            </Box>
             <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={UserProfileSchema} >
              {({isSubmitting, errors, touched}) => (
                <Form>
                  <Typography variant="mediumText">General</Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Field component={TextField} id="first_name" name="first_name" label="First Name" fullWidth margin="normal" size="normal" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={TextField} id="last_name" name="last_name" label="Last Name" fullWidth margin="normal" size="normal" />
                    </Grid>
                  </Grid>
                  <Field component={TextField} id="company_name" name="company_name" label="Company Name" fullWidth margin="normal" size="normal" />
                  <Field
                    component={TextField}
                    id="email"
                    name="email"
                    label="Email"
                    margin="normal"
                    size="normal"
                    defaultValue={user.email}
                    InputProps={{
                      endAdornment: <InputAdornment position="start" >
                        {user.email_verified ?
                          <Typography color={"success.dark"} fontWeight={500}>VERIFIED</Typography> :
                          <Typography color={"error.dark"} fontWeight={500}>UNVERIFIED</Typography>
                        }
                      </InputAdornment>,
                    }}
                    disabled
                    fullWidth
                  />

                  <Grid container spacing={3} >
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth  >
                      <Field
                        name="country_code"
                        component={Autocomplete}
                        options={countries}
                        getOptionLabel={(option: any) => `${option.label} (+${option.phone})`}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <MuiTextField
                            {...params}
                            name="country_code"
                            margin="normal"
                            error={touched['autocomplete'] && !!errors['autocomplete']}
                            helperText={touched['autocomplete'] && errors['autocomplete']}
                            label="Country Code"
                            variant="outlined"
                          />
                        )}
                      />
                      {touched.country_code && errors.country_code ? (
                          <FormHelperText error>{errors.country_code}</FormHelperText>
                       ) : null}

                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field component={TextField} name="mobile_phone" label="Mobile Phone Number" margin="normal" fullWidth  />
                  </Grid>
                  </Grid>


                  <Divider sx={{mt: 3, mb:3}}/>
                  <Typography variant="mediumText">Address</Typography>

                  <Field component={TextField} id="address_1" name="address_1" label="Address Line 1" fullWidth margin="normal" size="normal"  />
                  <Field component={TextField} id="address_2" name="address_2" label="Address Line 2" fullWidth margin="normal" size="normal"  />
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Field component={TextField} id="country" name="country" label="Country" fullWidth margin="normal" size="normal"  disabled value={userProfile.country_code.label} helperText={"Country is based on your Country Code."} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={TextField} id="postal_code" name="postal_code" label="Zip / Postal Code" fullWidth margin="normal" size="normal"  />
                    </Grid>
                  </Grid>

                  <Divider sx={{mt: 3, mb:3}}/>
                  <Typography variant="mediumText">Marketing</Typography>
                   <FormControl fullWidth  >
                    <Field
                      type="checkbox"
                      component={CheckboxWithLabel}
                      name="marketing"
                      label="Marketing Communication"
                      Label={{ label: 'I agree to receive marketing communication'}}
                    />
                  </FormControl>

                  <Typography align="right">
                      <Button sx={{mt: 2}} type="submit" variant="contained" color="secondary" disabled={isSubmitting}> Update Profile </Button>
                  </Typography>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>


  );
};

export default UserProfileForm;
