import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchUserProfile = createAsyncThunk('userProfile/fetchUserProfile', async () => {
  return authAxios.get('/profile').then(response => response.data);
});

export const updateUserProfile = createAsyncThunk('userProfile/updateUserProfile', (profileData) => {
  return authAxios.put(`/profile/`, profileData).then(response => response.data);
});

// Slice
export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Selector to get the current user's profile data
export const selectCurrentUserProfile = (state) => state.userProfile.data;

export default userProfileSlice.reducer;
