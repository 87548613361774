import * as React from 'react';
import {styled} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MainMenuList from "components/molecules/Menu/MainMenuList";
import MuiDrawer from "@mui/material/Drawer";
import Logo from "images/TPP_Logo_Rev.svg";
import UserMenu from "components/organisms/Menu/UserMenu";
import LogoIcon from "images/TPP_Icon_Rev.svg";
import {setMenuClose, setMenuOpen} from "redux/global/globalSlice";


const Drawer = styled(MuiDrawer, )(
  ({ theme }) => ({
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,

    },
    '& .MuiListItemIcon-root, & .MuiListItemText-root' : {
      color: theme.text.light
    },
  }),
);

export default function MobileMenu({active}) {
  const menuOpen = useSelector((state) => state.global.menuOpen);
  const dispatch = useDispatch();

  return (
    <Box >
      <AppBar position="static">
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" sx={{ mr: 2 }} onClick={() => dispatch(setMenuOpen())} >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: {xs: 'flex', sm: "flex"}, flexGrow: 1, alignItems: "center", justifyContent: "center", mt:1 }} >

            <a href="#"><img src={LogoIcon} alt="Portfolio Platform"  height={40} /></a>
          </Box>

          <Box>
            <UserMenu mobile={true} />
          </Box>

        </Toolbar>
      </AppBar>

      {active && <Drawer sx={{display: {  xs: 'block', sm: 'none' }}} anchor="left" open={menuOpen} onClose={() => dispatch(setMenuClose())}>
        <Box sx={{pt: 3, pb: 2, mb: 3  }}>
          <a href="#"><img src={Logo} alt="Portfolio Platform" width={240} height={40} style={{marginLeft: "8px", marginRight: "8px"}}/></a>
        </Box>
        <MainMenuList open={menuOpen} mobile={true} />
      </Drawer>}

    </Box>
  );
}