import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import authAxios from "utils/authAxios";

// Thunks
export const fetchSimulations = createAsyncThunk('simulation/fetchSimulations', async () => {
  return authAxios.get('simulation/simulations/').then(response => response.data);
});

export const createSimulation = createAsyncThunk('simulation/createSimulation', (simulationData) => {
  return authAxios.post('simulation/simulations/', simulationData).then(response => response.data);
});

export const updateSimulation = createAsyncThunk('simulation/updateSimulation', ({ simulationId, simulationData }) => {
  return authAxios.put(`simulation/simulations/${simulationId}/`, simulationData).then(response => response.data);
});

export const deleteSimulation = createAsyncThunk('simulation/deleteSimulation', (simulationId) => {
  return authAxios.delete(`simulation/simulations/${simulationId}/`).then(response => response.data);
});

export const fetchSimulationDetails = createAsyncThunk('simulation/fetchStripeDetails', async (simulationId) => {
  return authAxios.get(`simulation/simulations/${simulationId}/details/`).then(response => response.data);
});


export const simulationsAdapter = createEntityAdapter();
// Slice
export const simulationSlice = createSlice({
  name: 'simulation',
  initialState: simulationsAdapter.getInitialState({
    loading: false,
    error: null,
    simulationDetails: {},
  }),
  reducers: {
  },
   extraReducers: (builder) => {
    builder
      .addCase(fetchSimulations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSimulations.fulfilled, (state, action) => {
        simulationsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSimulations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createSimulation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSimulation.fulfilled, simulationsAdapter.addOne)
      .addCase(createSimulation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSimulation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSimulation.fulfilled, simulationsAdapter.upsertOne)
      .addCase(updateSimulation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSimulation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSimulation.fulfilled, (state, action) => {
        const simulationId = action.meta.arg;
        simulationsAdapter.removeOne(state, simulationId);
      })
      .addCase(deleteSimulation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSimulationDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSimulationDetails.fulfilled, (state, action) => {
        const simulationId = action.meta.arg;
        state.simulationDetails[simulationId] = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSimulationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  selectById: selectSimulationById,
  selectAll: selectAllSimulations,
} = simulationsAdapter.getSelectors((state) => state.simulation);

export const selectSimulationByStrategyId = (state, strategyId) => {
    return Object.values(state.simulation.entities).filter(simulation => simulation.strategy === strategyId);
};

export const selectAllSimulationStrategyIds = (state) => {
    return Object.values(state.simulation.entities).map(sub => sub.strategy);
};

export default simulationSlice.reducer;
