import * as React from "react";
import _ from "lodash";
import {Box} from '@mui/system';
import { useSelector } from 'react-redux';
import {CssBaseline} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import MainMenu from 'components/organisms/Menu/MainMenu';
import mainStyles from 'theme/mainStyles'
import UserMenu from "components/organisms/Menu/UserMenu";
import MobileMenu from "components/organisms/Menu/MobileMenu";
import InitialUserProfile from "views/InitialUserProfile";

export default function SimpleTemplate({children}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
  <Box id="main">
    <CssBaseline/>
    {mainStyles}
      <Box component="main" >
        <Box>
          {children}
        </Box>
      </Box>
  </Box>
  )
}
