import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from 'react-redux';
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import Typography from "@mui/material/Typography";
import BoardTable from "components/atoms/BoardTable/BoardTable";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Alert, Chip, Grid, Link} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "routes";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import {Box} from "@mui/system";
import {deleteSimulation, selectAllSimulations} from "redux/simulation/simulationSlice";
import {selectAllSubscriptionsInactive, selectAllSubscriptionsLive} from "redux/subscription/subscriptionSlice";
import Tooltip from "@mui/material/Tooltip";
import SettingsDropdown from "components/molecules/SettingsDropdown/SettingsDropdown";
import {enqueueSnackbar} from 'notistack';
import {useConfirmation} from "utils/ConfirmationProvider";


export default function AutoTrade () {
  const dispatch = useDispatch();
  const confirm = useConfirmation()
  const simulations = useSelector(selectAllSimulations);
  const subscriptionsInactive = useSelector(selectAllSubscriptionsInactive);
  const subscriptionsLive = useSelector(selectAllSubscriptionsLive);
  const strategies = useSelector(state => state.strategy.entities);

  const handleStopSimulation = async (simulationId, strategyName) => {
    confirm({
      title: `Stop simulating ${strategyName}`,
      message: <>Are you sure you want to turn off Simulated Trading for the strategy <b>{strategyName}</b>? <br></br>All historic performance data will be lost.</>,
      onConfirm: async () => {
        await dispatch(deleteSimulation(simulationId));
        enqueueSnackbar(`Simulation for "${strategyName}" has been stopped.`, { variant: 'success' })
      },
      maxWidth: "md"
    });
  }

  return (
    <>
      <PageHeader text1={"AutoTrade"} text2={"Control Panel"} />

      <Box sx={{mt: 5}}>
        <Typography variant="h3"> Currently AutoTrading </Typography>
        <Typography color={"secondary.dark"} fontWeight={500}>Strategies that are currently AutoTrading.</Typography>
      </Box>
      {_.isEmpty(simulations) && _.isEmpty(subscriptionsLive) ?
        <Box sx={{mt: 4}}>
          <Alert severity="info">
            You have no AutoTrading strategies. <br/>
            Please add strategies to <Link component={RouterLink} to={routes.dashboard}>Simulation</Link> or subscribe and activate AutoTrading.
          </Alert>
        </Box> :
        <BoardTable>
          <TableHead>
            <TableRow>
              <TableCell>Strategy</TableCell>
              <TableCell>Status</TableCell>
              <TableCell >Your Broker</TableCell>
              <TableCell align="right">Strategy <br/>Model Account</TableCell>
              <TableCell align="right">Your  <br/>Scaling %</TableCell>
              <TableCell align="right">Your <br/>Stop Loss</TableCell>
              <TableCell align="center" sx={{ width: 'auto'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {subscriptionsLive.map((subscription, index) => {
              const strategy = strategies[subscription.strategy];

              return (
                strategy &&
                <TableRow >
                  <TableCell className="light2">
                    <Grid container>
                      <Grid item md={12} lg={6}>
                        <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                          <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                        </RouterLink>
                      </Grid>
                      <Grid item md={12} lg={6}>
                        <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>
                          <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                            <Button color="primary" variant="outlined" size="small" > Details </Button>
                          </RouterLink>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell >
                    <Chip label="LIVE" size="small" color="success"/>
                  </TableCell>

                  <TableCell>
                    {strategy.broker.name}
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" >$41,500</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" >100%</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" > N/A </Typography><br/>
                  </TableCell>
                  <TableCell align="right" >
                    <SettingsDropdown>
                      <MenuItem component={RouterLink} to={routes.autoTradeFormEdit.replace(":strategyId", strategy.id)}>Edit AutoTrade</MenuItem>
                    </SettingsDropdown>

                  </TableCell>
                </TableRow>
              )})}

            {simulations.map((simulation, index) => {
              const strategy = strategies[simulation.strategy];

              return (
                strategy &&
                <TableRow >
                  <TableCell className="light2">
                    <Grid container>
                      <Grid item md={12} lg={6}>
                        <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                          <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                        </RouterLink>
                      </Grid>
                      <Grid item md={12} lg={6}>
                        <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>
                          <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                            <Button color="primary" variant="outlined" size="small" > Details </Button>
                          </RouterLink>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell >
                    <Chip label="SIMULATING" size="small" variant="x" color="secondary"/>
                  </TableCell>

                  <TableCell>
                    {strategy.broker.name}
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" >$41,500</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" >100%</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText" > N/A </Typography><br/>
                  </TableCell>
                  <TableCell align="right" >
                    <SettingsDropdown>
                      <MenuItem component={RouterLink} to={routes.strategyDetails.replace(":strategyId", strategy.id)}>Subscribe to Strategy</MenuItem>
                      <Tooltip title="You have to subscribe to enable AutoTrading" >
                        <span sx={{mb: 1}}> <MenuItem disabled>Change to Live &nbsp; (AutoTrade)</MenuItem> </span>
                      </Tooltip>
                      <Divider sx={{mt: 1, mb: 1}}/>
                      <MenuItem onClick={() => handleStopSimulation(simulation.id, strategy.name.toUpperCase())}>Stop Simulation</MenuItem>
                    </SettingsDropdown>

                  </TableCell>
                </TableRow>
              )})}

          </TableBody>
        </BoardTable>
      }


      <Box sx={{mt: 10}}>
        <Typography variant="h3"> Available for Live AutoTrading </Typography>
        <Typography color={"secondary.dark"} fontWeight={500}>Strategies that you have already subscribed to, but which you are not currently AutoTrading.</Typography>
      </Box>

      {_.isEmpty(subscriptionsInactive) ?
        <Box sx={{mt: 4}}>
          <Alert severity="info">
            You have no Inactive strategies. <br/>
            {!subscriptionsLive && <>Please subscribe to strategy to be able to activate AutoTrading.</>}
          </Alert>
        </Box> :

        <BoardTable>
          <TableHead>
            <TableRow>
              <TableCell>Strategy</TableCell>
              <TableCell align="right">Return</TableCell>
              <TableCell align="right">Last 30 days</TableCell>
              <TableCell align="right">Last 60 days</TableCell>
              <TableCell align="right">Last 90 days</TableCell>
              <TableCell align="center" sx={{ width: 'auto'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsInactive.map((subscription, index) => {
              const strategy = strategies[subscription.strategy];

              return (
                strategy &&
                <TableRow >
                  <TableCell className="light2">
                    <Grid container>
                      <Grid item md={12} lg={6}>
                        <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                          <Typography variant="h4" sx={{mb:1}} color="primary"> {strategy.name.toUpperCase()} </Typography>
                        </RouterLink>
                      </Grid>
                      <Grid item md={12} lg={6}>
                        <Box display="flex" justifyContent={{xs: "flex-start", lg: "flex-end"}} mt={{xs: 1, lg: 0}}>
                          <RouterLink>
                            <RouterLink to={routes.strategyDetails.replace(":strategyId", strategy.id)}>
                              <Button color="primary" variant="outlined" size="small" > Details </Button>
                            </RouterLink>
                          </RouterLink>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell align="right" className="light">
                    <Typography variant="mediumText">3.0%</Typography><br/>
                    <Typography variant="smallText">Annual Return</Typography>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText">2.1%</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText">6.8%</Typography><br/>
                  </TableCell>
                  <TableCell align="right" className="light">
                    <Typography variant="mediumText">6.9%</Typography><br/>
                  </TableCell>

                  <TableCell align="right">
                    <RouterLink to={routes.autoTradeFormCreate.replace(":strategyId", strategy.id)}>
                      <Button variant="contained" color="secondary" >Set up autotrade </Button>
                    </RouterLink>
                  </TableCell>
                </TableRow>
              )})}

          </TableBody>
        </BoardTable>
      }
    </>
  )
}