import React from "react";
import * as Yup from 'yup';
import _ from "lodash";
import {useLocation, useParams} from "react-router-dom";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import {routes} from "routes";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import {Card, FormControl, Grid, InputLabel, Select} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import LinkIcon from '@mui/icons-material/Link';
import {Field, Form, Formik} from 'formik';
import {Select as SelectInput} from 'formik-mui';
import {Box} from "@mui/system";
import {enqueueSnackbar} from 'notistack';
import {createLinkedAccount} from "redux/linkedAccount/linkedAccountSlice";
import {selectKYCStatus} from "redux/kyc/kycSlice";
import {useConfirmation} from "utils/ConfirmationProvider";


export default function BrokerAccountConnect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirmation()
  const KYCStatus = useSelector(selectKYCStatus);

  const searchParams = new URLSearchParams(location.search);
  const broker = searchParams.get('broker');

  const handleSubmit = async (values) => {

    if(_.isEmpty(KYCStatus) || KYCStatus.status !== "verified") {
      confirm({
      title: `KYC Verification Required`,
      message: <>Your KYC status is not VERIFIED. <br></br>Please request new KYC verification or check existing status. <br></br><br></br>You will be redirected to your Profile KYC status page.</>,
      onConfirm: () => {
        navigate(`${routes.account}?tab=kyc`);
      },
    });

    } else {
      await dispatch(createLinkedAccount(values)).unwrap();
      enqueueSnackbar(`Linked Account connected successfully.`, { variant: 'success' })
      navigate(routes.brokerAccountList);
    }

  };

  const initialValues = {
    broker: broker || ''
  };

  const BrokerAccountSchema = Yup.object().shape({
    broker: Yup.string().required('Broker is required'),
  });

  return (
    <>
      <PageHeader text1={"Connect"} text2={"Broker Account"} description={"Connect new Broker Account"}/>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Card sx={{mt: 3, p: 3}}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={BrokerAccountSchema}
            >
              {({isSubmitting}) => (
                <Form>
                  <FormControl fullWidth margin="dense">
                    <Field
                      component={SelectInput}
                      id="broker"
                      name="broker"
                      label="Broker"
                      disabled={!!broker}
                    >
                      <MenuItem value="interactive_brokers" selected>Interactive Brokers</MenuItem>
                    </Field>
                  </FormControl>

                  <Typography align="right">
                    <Button sx={{mt: 3}} type="submit" variant="contained" color="secondary" endIcon={<LinkIcon/>}
                     disabled={isSubmitting}> Connect </Button>
                  </Typography>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{mt: 3}}>
            <Typography variant="h5">How to connect Broker Account?</Typography>
            <Typography component="p" sx={{mt: 2}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit
              amet dolor ac ligula condimentum ornare id vel lacus. Cras a consectetur est. Morbi consectetur lacus
              neque, vitae egestas tellus feugiat sagittis. Suspendisse vel pulvinar nulla, at iaculis arcu. Vivamus
              fringilla dictum ligula vitae aliquam. Nam malesuada eros eget sagittis eleifend. Aliquam id porttitor
              eros. Nullam quis urna a magna imperdiet auctor a eu dui. Maecenas dictum pellentesque tortor, eget
              sollicitudin erat finibus varius. Nam suscipit sagittis justo ac rhoncus. Pellentesque accumsan, est vel
              fermentum scelerisque, tortor dui fermentum ligula, in hendrerit mauris leo non nulla. Interdum et
              malesuada fames ac ante ipsum primis in faucibus. Pellentesque mollis semper sodales. Cras eget tincidunt
              velit. Duis laoreet, sem eget tristique tempor, elit leo porta purus, vel condimentum magna velit eu mi.
              Pellentesque sit amet sem cursus, scelerisque arcu at, vehicula justo.</Typography>
          </Box>
        </Grid>

      </Grid>
    </>
  )
}