import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import {PageHeader} from "components/molecules/PageHeader/PageHeader";
import TabPanel from "components/atoms/TabPanel/TabPanel";
import UserProfileForm from "components/organisms/AccountSections/UserProfileForm";
import Subscriptions from "components/organisms/AccountSections/Subscriptions";
import Kyc from "components/organisms/AccountSections/Kyc";


export default function Account() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();

  useEffect(() => {
    // Function to parse the query string
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    if (tab === 'general') {
      setValue(0);
    } else if (tab === 'subscriptions') {
      setValue(1);
    } else if (tab === 'kyc') {
      setValue(2);
    }
  }, [location.search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageHeader text1={"Account"} text2={"Management"} sx={{ mb: 4}} />

      <Tabs
        value={value}
        onChange={handleChange}
         variant="scrollable"
          allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
         sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="General" />
        <Tab label="Subscriptions & Plans" />
        <Tab label="KYC" />
      </Tabs>



      <Box sx={{ pt: 4 }}>
        <TabPanel name="general" value={value} index={0}>
          <UserProfileForm />
        </TabPanel>
        <TabPanel name="subscriptions" value={value} index={1}>
          <Subscriptions />
        </TabPanel>
        <TabPanel name="kyc" value={value} index={2}>
          <Kyc />
        </TabPanel>
      </Box>
    </>
  )
}